import React, { useEffect, useMemo, useState } from 'react'
import AddPaymentUi from './AddPaymentUi'
import { CreatePaymentStatusApi, fetchLogsApi } from '../../apis/stock.api';
import { PAYMENT_STATUS, SNACK_BAR_VARIETNS } from '../../utils/constants';
import { callApiAction } from '../../store/actions/commonAction';
import useValidate from '../../store/hooks/useValidator';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { closeModal } from '../../store/actions/modalAction';
import { callSnackBar } from '../../store/actions/snackbarAction';

const AddPaymentController = () => {
    const title = "Add Payment";
    const validate = useValidate()
    const dispatch = useDispatch()


    const [loading, setLoading] = useState(false)
    // const { settings } = useSelector((state) => state)


    const [fields, setFields] = useState({
        err: '',
        date: moment(),
        // invoice_no: '',
        total_amount: '',
        // inventory_type:1,
        // product:"",
        // amount_per_piece:null,
        // quantity:null,
        // payment_status:PAYMENT_STATUS.COMPLETED
    })

    const validationSchemaForCreate = useMemo(() => ([

        // {
        //     required: true,
        //     value: fields.date,
        //     field: 'date',
        // },
        // {
        //     required: true,
        //     value: fields.invoice_no,
        //     field: 'Invoice no',
        // },
        {
            required: true,
            value: fields.total_amount,
            field: 'Total Amount',
        },
        // {
        //     required: true,
        //     value: fields.product,
        //     field: 'Product',
        // },
        // {
        //     required: true,
        //     value: fields.amount_per_piece,
        //     field: 'Amount Per Piece',
        // },
        // {
        //     required: true,
        //     value: fields.quantity,
        //     field: 'Quantity',
        // },
    ]), [fields])
    const createFunction = async (e) => {
        e.preventDefault();
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await CreatePaymentStatusApi(fields),
                    async (response) => {

                        // await callBack(response)
                        setLoading(false)
                        dispatch(callSnackBar("Created Successfully", SNACK_BAR_VARIETNS.suceess))

                        dispatch(closeModal("AddPayment"))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })

                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }
    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: "",
        sort: "createdAt",
        sortDirection: -1,
        isLedger: true
    });

    const fetchApi = fetchLogsApi;
    const [listProblem, setlistProblem] = useState()

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                console.log("Responses", response)
                setlistProblem(response.result)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }

    useEffect(() => {
        fetchList()
    }, [filters])

    return (
        <AddPaymentUi
            listProblem={listProblem}
            title={title}
            loading={loading}
            fields={fields}
            setFields={setFields}
            createFunction={createFunction}
        />
    )
}

export default AddPaymentController