import {
  Autocomplete,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../components/tables/DataTable";
import AsyncSearchBar from "../../components/inputs/AsyncSearchBar";
import SubmitButton from "../../components/button/SubmitButton";
import AddIcon from "@mui/icons-material/Add";
import { USER_ROLES } from "../../utils/constants";


const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  flexWrap: "wrap",
  marginBottom: "10px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "rpw",
  },
}));

const LedgerListUi = ({
  title,
  filters,
  setFilters,
  loading,
  list,
  setList,
  columns,
  downloadPdf,
  downloadLoading,
  tab, setTab, onAddPayment
}) => {
  const { user } = useSelector((state) => state);
  const totals = list?.result?.reduce(
    (acc, row) => {
      acc.debit += row.debit || 0;
      acc.credit += row.credit || 0;
      return acc;
    },
    { debit: 0, credit: 0 }
  );

  return (
    <>
      <Box mb={3}>
        <Paper elevation={2} sx={{ width: "100%", padding: 4, minHeight: "600px" }}>
          <Typography variant="h3" color={"#000"}>
            {title}
          </Typography>
          <Box mb={3} mt={3} >
            <FilterTitleBox mb={4}>
              {user.data.role == USER_ROLES.WEHEAR_ADMIN && <Box mr={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button variant="contained" onClick={onAddPayment} loading={loading} sx={{ height: "40px", fontSize: "16px", fontWeight: 700, fontFamily: "Public Sans" }}>
                  <AddIcon /> &nbsp;
                  <Typography variant="h5">
                    Add Payment
                  </Typography>
                </Button>
              </Box>}


              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button variant="contained" onClick={downloadPdf} loading={downloadLoading} sx={{ height: "40px", fontSize: "16px", fontWeight: 700, fontFamily: "Public Sans" }}>
                  <Typography variant="h5">
                    Ledger Download
                  </Typography>
                </Button>
              </Box>
            </FilterTitleBox>

            {/* <FiltersBox mt={5}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <AsyncSearchBar
                  fullWidth
                  title="Search Product Name"
                  size="small"
                  placeholder={"Search Product Name"}
                  defaultValue={filters.search}
                  onChange={(changedVal) => {
                    setFilters({
                      ...filters,
                      pageNo: 1,
                      pageSize: 10,
                      search: changedVal,
                    });
                  }}
                />
              </Box>
            </FiltersBox> */}
          </Box>

          <Box mt={2}>
            <ButtonGroup
              sx={{ display: "flex" }}
              size="large"
              aria-label="Large button group"
            >
              <Button
                onClick={() => setTab(0)}
                sx={{
                  flexGrow: 4,
                  backgroundColor: tab == 0 ? "#020043" : "white",
                  color: tab == 0 ? "white" : "#28B867",
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  fontFamily: "Public Sans",
                  fontWeight: 700,
                  height: "40px",
                }}
                variant="text"
                key="one"
              >
                OVERALL
              </Button>
              <Button
                onClick={() => setTab(1)}
                sx={{
                  flexGrow: 4,
                  backgroundColor: tab == 1 ? "#020043" : "white",
                  color: tab == 1 ? "white" : "#28B867",
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  fontFamily: "Public Sans",
                  fontWeight: 700,
                  height: "40px",
                }}
                variant="text"
                key="two"
              >
                CREDIT
              </Button>
              <Button
                onClick={() => setTab(2)}
                sx={{
                  flexGrow: 4,
                  backgroundColor: tab == 2 ? "#020043" : "white",
                  color: tab == 2 ? "white" : "#28B867",
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  fontFamily: "Public Sans",
                  fontWeight: 700,
                  height: "40px",
                }}
                variant="text"
                key="three"
              >
                DEBIT
              </Button>
            </ButtonGroup>
          </Box>

          <Box>
            {/* <Paper sx={{ width: '100%', overflow: 'hidden' }}> */}
            <TableContainer
              sx={{
                minHeight: 400,
                border: "1px solid #CECCFF",
                borderRadius: "5px",
                marginTop: "20px",
              }}
            >
              <Table>
                <TableHead
                  sx={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#EDEDF7",
                  }}
                >
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        sx={{
                          fontFamily: "Public Sans",
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "#020043",
                        }}
                        key={column.id}
                        align={column.align}
                      // style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>

                  </TableRow>
                  <TableRow>

                  </TableRow>
                  <TableRow></TableRow>
                </TableHead>
                {list?.result?.length == 0 ? (
                  <TableBody sx={{ borderBottom: "none" }}>
                    {loading &&
                      [0, 1, 2, 3].map((row) => (
                        <TableRow key={row} sx={{ borderBottom: "none" }}>
                          <TableCell>
                            <Skeleton
                              animation="pulse"
                              width={"100%"}
                              height={"50px"}
                            />
                          </TableCell>
                        </TableRow>
                      ))}

                    {!loading && list?.result?.length == 0 && (
                      <TableRow sx={{ minHeight: 400 }}>
                        <TableCell colSpan={8} sx={{ borderBottom: "none" }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              height: "250px"
                            }}
                          >
                            <Typography variant="h5">No Data Found</Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>) :
                  <TableBody sx={{ borderBottom: "none" }}>
                    {!loading &&
                      Array.isArray(list.result) &&
                      list.result.map((row, rowIndex) => (
                        <TableRow>
                          {columns.map((column, colIndex) => {
                            return (
                              <TableCell
                                key={column.id}
                                sx={{
                                  maxWidth: column.maxWidth ?? "200px",
                                  wordWrap: "break-word",
                                  borderBottom: "none"
                                  // borderBottom: isLastTwoColumns ? "1 px solid #e0e0e0" : "none",
                                }}
                                align={column.align ?? "left"}
                              >
                                {column.renderValue
                                  ? column.renderValue(row)
                                  : row[column.fieldName]}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}

                    <TableRow >
                      {columns.map((column, colIndex) => {
                        // const isLastThreeRows =
                        // rowIndex >= list.result.length - 3;
                        const isLastTwoColumns =
                          colIndex >= columns.length - 2;
                        return (
                          <>
                            {column?.fieldName == "debit" ?
                              (
                                <TableCell
                                  key={column.id}
                                  sx={{
                                    maxWidth: column.maxWidth ?? "200px",
                                    wordWrap: "break-word",
                                    borderTop: isLastTwoColumns ? "1px solid #e0e0e0" : "none",
                                    borderBottom: "none"
                                  }}
                                  align={column.align ?? "right"}
                                >
                                  {totals?.debit ?? ""}

                                </TableCell>)
                              :
                              <TableCell
                                key={column.id}
                                sx={{
                                  maxWidth: column.maxWidth ?? "200px",
                                  wordWrap: "break-word",
                                  borderBottom: "none",
                                  borderTop: isLastTwoColumns ? "1px solid #e0e0e0" : "none",
                                }}
                                align={column.align ?? "right"}
                              >
                                {column.fieldName == "credit" ? (totals?.credit ?? "") : ""}
                              </TableCell>
                            }
                          </>
                        );
                      })}
                    </TableRow>
                    <TableRow >
                      {columns.map((column, colIndex) => {
                        const isLastTwoColumns =
                          colIndex >= columns.length - 2;
                        return (
                          <TableCell
                            key={column.id}
                            sx={{
                              maxWidth: column.maxWidth ?? "200px",
                              wordWrap: "break-word",
                              borderBottom: isLastTwoColumns ? "1 px solid #e0e0e0" : "none",
                            }}
                            align={column.align ?? "left"}
                          >
                            {column.fieldName == "product" ? "Closing Balance" : (column.fieldName == "credit" ? Math.abs(totals?.debit - totals?.credit) : "")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow >
                      {columns.map((column, colIndex) => {
                        const isLastTwoColumns =
                          colIndex >= columns.length - 2;
                        return (
                          <>
                            {column?.fieldName == "credit" ?
                              (<TableCell
                                key={column.id}
                                sx={{
                                  maxWidth: column.maxWidth ?? "200px",
                                  wordWrap: "break-word",
                                  borderBottom: isLastTwoColumns ? "1 px solid #e0e0e0" : "none",

                                }}
                                align={column.align ?? "left"}
                              >
                                {(totals?.credit + (totals?.debit - totals?.credit)) ?? " "}
                              </TableCell>)
                              :
                              (<TableCell
                                key={column.id}
                                sx={{
                                  maxWidth: column.maxWidth ?? "200px",
                                  wordWrap: "break-word",
                                  // borderBottom: "none",
                                  borderBottom: isLastTwoColumns ? "1 px solid #e0e0e0" : "none",

                                }}
                                align={column.align ?? "left"}
                              >
                                {column?.fieldName == "debit" ? totals?.debit ?? "" : ""}
                              </TableCell>)}
                          </>
                        );
                      })}
                    </TableRow>
                  </TableBody>}
              </Table>
            </TableContainer>
          </Box>
          {/* <Box sx={{ minHeight: "40vh" }}>
            <DataTable
              columns={columns}
              rows={list?.result ? list?.result : []}
              count={list?.result?.length ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
            />
          </Box> */}
        </Paper>
      </Box>
    </>
  );
};
export default LedgerListUi;