import {
  AddShoppingCart,
  Apartment,
  ColorLens,
  DashboardCustomize,
  DomainAdd,
  Flare,
  FormatListBulleted,
  Groups2Outlined,
  PersonAdd,
  Settings,
  ShowChart,
  SupportAgent,
} from "@mui/icons-material";
import { Navigate } from "react-router-dom";
import AppContainer from "./components/layouts/common/AppContainer";
import SignInController from "./pages/signin/SignInController";
import ListController from "./pages/user/UserLIstController";
import ProductTypeController from "./pages/product-type/ProductTypeController";
import PagenotFound from "./components/layouts/PagenotFound";
import ProductColorController from "./pages/product-color/ProductColorController";
import ProductListController from "./pages/products/ProductListController";
import DashboardListController from "./pages/dashboard/DashboardListController";
import CustomerCreateController from "./pages/customer/CustomerCreateController";
import MODULES from "./utils/module.constant";
import StepperController from "./pages/customer/StepperController";
import Stock from "./pages/stock/Stock";
import SalesListController from "./pages/sales/SalesListController";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { USER_ROLES } from "./utils/constants";
import CustomerListController from "./pages/customer/CustomerListController";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

import StockOfProductListController from "./pages/StockProduct/StockOfProductListController";
import SalesPersonListController from "./pages/salesPerson/SalesPersonListController";
import LedgerListController from "./pages/ledger/LedgerListController";
import Customer from "./pages/customer/Customer";
const defaultRedirect = (user) => {
  if (!user.isLoggedIn) {
    return "/sign-in";
  }
  // if (user.data.modules?.includes(MODULES.DASHBOARD_MODULE)) {
  //   return "/dashboard";
  // }
  if (user.data.modules?.includes(MODULES.INVENTORY_MODULE)) {
    return "/inventory-stock";
  }
  if (user.data.modules?.includes(MODULES.USER_MODULE)) {
    return "/users";
  }
  if (user.data.modules?.includes(MODULES.STOCK_MODULE)) {
    return "/stock";
  }
  if (user.data.modules?.includes(MODULES.SALES)) {
    return "/sales";
  }
  if (user.data.modules?.includes(MODULES.CUSTOMER_MODULE)) {
    return "/customer";
  }

  if (user.data.modules?.includes(MODULES.PRODUCT_MODULE)) {
    return "/product";
  }
  if (user.data.modules?.includes(MODULES.SALES_PERSON_MODULE)) {
    return "/sales-person";
  }

  // if (user.data.modules?.includes(MODULES.CUSTOMER_MODULE)) {
  //   return "/customer";
  // }

  if (user.data.modules?.includes(MODULES.PRODUCT_COLOR_MODULE)) {
    return "/product-color";
  }

  if (user.data.modules?.includes(MODULES.PRODUCT_TYPE_MODULE)) {
    return "/product-type";
  }
  if (user.data.modules?.includes(MODULES.LEDGER_MODULE)) {
    return "/ledger";
  }
  return "/sign-in";
};
const moduleAccessCondition = (user, modules = [], roles = []) => {
  if (!user?.isLoggedIn) return false;

  let moduleAcces = false;
  let roleAccess = false;
  const userAllowedModules = user.data?.modules ?? [];
  const userRole = user.data?.role;

  for (let userAllowedModule of userAllowedModules) {
    for (let accessModule of modules) {
      if (userAllowedModule == accessModule) {
        moduleAcces = true;
        break;
      }
    }
  }
  roleAccess = roles?.includes(userRole);
  return moduleAcces || roleAccess;
};
const defineRoutes = (user) => {
  const redirectDefault = defaultRedirect(user);
  return [
    {
      path: "",
      element: !user.isLoggedIn ? (
        <SignInController />
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
      hideInPannel: true,
    },
    {
      path: "sign-in",
      element: !user.isLoggedIn ? (
        <SignInController />
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
      hideInPannel: true,
    }, {
      path: "inventory-stock",
      icon: <Apartment />,
      title: "Inventory Stock",

      hideInPannel: !moduleAccessCondition(user, [MODULES.INVENTORY_MODULE]),
      element: moduleAccessCondition(user, [MODULES.INVENTORY_MODULE]) ? (
        <AppContainer>
          <StockOfProductListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "stock",
      icon: <DashboardCustomize />,
      title: "Stock",
      element: <Navigate replace to={redirectDefault} />,
      hideInPannel: !moduleAccessCondition(user, [MODULES.STOCK_MODULE]),
      element: moduleAccessCondition(user, [MODULES.STOCK_MODULE]) ? (
        <AppContainer>
          <Stock />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },

    {
      path: "customer",
      icon: <SupportAgent />,
      title: "Customer",
      hideInPannel: moduleAccessCondition(user, [USER_ROLES.LENSKART_ADMIN === user.data.role || user.data.role === USER_ROLES.AUDIOLOGIST && MODULES.CUSTOMER_MODULE || user.data.role === USER_ROLES.WEHEAR_ADMIN && MODULES.CUSTOMER_MODULE]),
      element: moduleAccessCondition(user, [MODULES.CUSTOMER_MODULE]) ? (
        <AppContainer></AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),

      children: [
        {
          path: "",
          title: "Customer Details",
          icon: SupervisedUserCircleIcon,
          hideInPannel: !moduleAccessCondition(user, [MODULES.CUSTOMER_MODULE]),

          element: moduleAccessCondition(user, [MODULES.CUSTOMER_MODULE]) ? (
            <Customer />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },

        {
          path: "create",
          title: "New Customer",
          icon: GroupAddIcon,
          hideInPannel: !moduleAccessCondition(user, [MODULES.CUSTOMER_MODULE]),

          element: moduleAccessCondition(user, [MODULES.CUSTOMER_MODULE]) ? (
            <StepperController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
      ],
    },

    {
      path: "customer",
      icon: <Groups2Outlined />,
      title: "Customer Details",
      // element: <Navigate replace to={redirectDefault} />,
      hideInPannel: !moduleAccessCondition(user, [user.data.role === USER_ROLES.LENSKART_ADMIN && MODULES.CUSTOMER_MODULE || user.data.role === USER_ROLES.WEHEAR_ADMIN && MODULES.CUSTOMER_MODULE || user.data.role === USER_ROLES.AUDIOLOGIST && MODULES.CUSTOMER_MODULE]),
      element: moduleAccessCondition(user, [MODULES.CUSTOMER_MODULE]) ? (
        <AppContainer>
          <Customer />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "sales",
      icon: <PointOfSaleIcon />,
      title: "Sales",
      element: <Navigate replace to={redirectDefault} />,
      hideInPannel: !moduleAccessCondition(user, [MODULES.SALES]),

      element: moduleAccessCondition(user, [MODULES.SALES]) ? (
        <AppContainer>
          <SalesListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },


    {
      path: "sales-person",
      icon: <PersonAdd />,
      title: "Sales Person",
      element: <Navigate replace to={redirectDefault} />,
      hideInPannel: !moduleAccessCondition(user, [MODULES.SALES_PERSON_MODULE]),

      element: moduleAccessCondition(user, [MODULES.SALES_PERSON_MODULE]) ? (
        <AppContainer>
          <SalesPersonListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "ledger",
      icon: <Flare />,
      title: "Ledger",
      element: <Navigate replace to={redirectDefault} />,
      hideInPannel: !moduleAccessCondition(user, [MODULES.LEDGER_MODULE]),
      element: moduleAccessCondition(user, [MODULES.LEDGER_MODULE]) ? (
        <AppContainer>
          <LedgerListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },





    {
      path: "users",
      icon: <Groups2Outlined />,
      title: "Users",
      hideInPannel: !moduleAccessCondition(user, [MODULES.USER_MODULE]),
      element: moduleAccessCondition(user, [MODULES.USER_MODULE]) ? (
        <AppContainer>
          <ListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },

    {
      path: "settings",
      icon: <Settings />,
      title: "Settings",
      hideInPannel: !moduleAccessCondition(user, [
        MODULES.PRODUCT_COLOR_MODULE,
        MODULES.PRODUCT_TYPE_MODULE,
      ]),
      element: moduleAccessCondition(user, [
        MODULES.PRODUCT_COLOR_MODULE,
        MODULES.PRODUCT_TYPE_MODULE,
      ]) ? (
        <AppContainer />
      ) : (
        <Navigate replace to={redirectDefault} />
      ),

      children: [{
        path: "product",
        icon: AddShoppingCart,
        title: "Product",

        hideInPannel: !moduleAccessCondition(user, [MODULES.PRODUCT_MODULE]),
        element: moduleAccessCondition(user, [MODULES.PRODUCT_MODULE]) ? (

          <ProductListController />

        ) : (
          <Navigate replace to={redirectDefault} />
        ),
      },
      {
        path: "product-color",
        title: "Product Color",
        icon: ColorLens,

        hideInPannel: !moduleAccessCondition(user, [
          MODULES.PRODUCT_COLOR_MODULE,
        ]),
        element: moduleAccessCondition(user, [
          MODULES.PRODUCT_COLOR_MODULE,
        ]) ? (
          <ProductColorController />
        ) : (
          <Navigate replace to={redirectDefault} />
        ),
      },
      {
        path: "product-type",
        title: "Product Type",
        icon: FormatListBulleted,

        hideInPannel: !moduleAccessCondition(user, [
          MODULES.PRODUCT_TYPE_MODULE,
        ]),
        element: moduleAccessCondition(user, [
          MODULES.PRODUCT_TYPE_MODULE,
        ]) ? (
          <ProductTypeController />
        ) : (
          <Navigate replace to={redirectDefault} />
        ),
      },
      ],
    },

    {
      path: "*",
      element: !user.isLoggedIn ? (
        <Navigate replace to={"/sign-in"} />
      ) : (
        <AppContainer>
          <PagenotFound />
        </AppContainer>
      ),
      hideInPannel: true,
    },
  ];
};
export default defineRoutes;

/* 

================================================================
Demo Object
================================================================
        {
            path: "sign-in",
            element: !user.isLoggedIn ? <SignInController /> : <Navigate replace to="/leaves" />,
            hideInPannel:true, //for showing it onSide pannel or not
            title: "Leave Management", // it will use as name in side navbar
            component:<></>


             children: [
                {
                    path: "",
                    title: "My Leaves",// it will use as name in side navbar
                    element: <MyLeaveController />,
                    hideInPannel:true,
                }
            ],
        },




================================================================
================================================================


*/
