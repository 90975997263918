import {
  Button,
  ButtonGroup,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import { Box } from "@mui/system";
import DataTable from "../../components/tables/DataTable";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import AsyncSearchBar from "../../components/inputs/AsyncSearchBar";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { USER_ROLES } from "../../utils/constants";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const SalesPersonListUi = ({
  title,
  filters,
  setFilters,
  list,
  loading,
  onCreateBtnClick,
  columns,
}) => {

  const { user } = useSelector(state => state)

  return (
    <>
      <Box mb={3}>
        <Paper elevation={2} sx={{ width: "100%", padding: 4 }}>
          <Box mb={4} mt={3} ml={3}>
            <FilterTitleBox>
              <Typography variant="h3" color={"#000"}>
                {title}
              </Typography>

              <Box>
                {user.data.role === USER_ROLES.LENSKART_ADMIN && <Button
                  onClick={onCreateBtnClick}
                  sx={{
                    width: "100%",
                    height: "6vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  variant="contained"
                >
                  <AddIcon /> &nbsp;
                  <Typography variant="h4" sx={{ display: "flex" }}>
                    Add Sales Person
                  </Typography>
                </Button>}
              </Box>
            </FilterTitleBox>

            <FiltersBox mt={5}>
              <PaddingBoxInDesktop
                mb={4}
                sx={{ display: "flex", width: "20vw" }}
              >
                <AsyncSearchBar
                  fullWidth
                  title="Search Name | Email"
                  size="small"
                  placeholder={"Search Name | Email"}
                  defaultValue={filters.search}
                  onChange={(changedVal) => {
                    setFilters({
                      ...filters, pageNo: 1,
                      pageSize: 10, search: changedVal
                    });
                  }}
                />
              </PaddingBoxInDesktop>


            </FiltersBox>
          </Box>

          <Box sx={{ minHeight: "40vh" }}>
            <DataTable
              columns={columns}
              rows={list.result ? list.result : []}
              count={list.total ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default SalesPersonListUi;
