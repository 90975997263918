import React from 'react'
import PropTypes from 'prop-types';
import { useState } from "react"
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material';
import CustomerCreateController from './CustomerCreateController';
import AudioGram from './AudioGram';
import moment from 'moment';
import TimelineIcon from '@mui/icons-material/Timeline';
import CustomerCreateUi from './CustomerCreateUi';
import useValidate from '../../store/hooks/useValidator';
import { callSnackBar } from '../../store/actions/snackbarAction';
import { SNACK_BAR_VARIETNS } from '../../utils/constants';
import { callApiAction } from '../../store/actions/commonAction';
import { useDispatch } from 'react-redux';
import { addCustomerApi, getCustomerByIdApi } from '../../apis/customer.api';
import { useNavigate } from 'react-router';
import CustomDialog from '../../components/layouts/common/CustomDialog';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TrialsUi from './TrialsUi';
import CustomerProductPurchaseUi from './CustomerProductPurchaseUi';
import CustomerProductReturnUi from './CustomerProductReturnUi';
import { CenteredBox } from '../../components/layouts/OneViewBox';



const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
        ...theme.applyStyles('dark', {
            borderColor: theme.palette.grey[800],
        }),
    },
}));

const QontoStepIconRoot = styled('div')(({ theme }) => ({
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        // zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 4,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    ...theme.applyStyles('dark', {
        color: theme.palette.grey[700],
    }),
    variants: [
        {
            props: ({ ownerState }) => ownerState.active,
            style: {
                color: '#784af4',
            },
        },
    ],
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 15,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: "linear-gradient(to right, rgba(16, 21, 54, 0.8), rgba(0, 0, 0, 0.8))",

        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: "linear-gradient(to right, rgba(16, 21, 54, 0.8), rgba(0, 0, 0, 0.8))"
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
        ...theme.applyStyles('dark', {
            backgroundColor: theme.palette.grey[800],
        }),
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 37,
    height: 37,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...theme.applyStyles('dark', {
        backgroundColor: theme.palette.grey[700],
    }),
    variants: [
        {
            props: ({ ownerState }) => ownerState.active,
            style: {
                backgroundImage: "linear-gradient(to right, rgba(16, 21, 54, 0.5), rgba(0, 0, 0, 0.8))",

                // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
            },
        },
        {
            props: ({ ownerState }) => ownerState.completed,
            style: {
                backgroundImage: "linear-gradient(to right, rgba(16, 21, 54, 0.5), rgba(0, 0, 0, 0.8))",

            },
        },
    ],
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <GroupAddIcon fontSize='small' />,
        2: <TimelineIcon fontSize='small' />,
        3: <VideoLabelIcon fontSize='small' />,
        4: <ShoppingCartIcon fontSize='small' />,
        // 5: <LocalShippingIcon fontSize='small' />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const steps = ['Customer Details', 'Audiogram', 'Trial', "Purchase"];
const StepperUi = ({ activeStep, fields, setFields, NextSteps, SkipSteps, id, setLoading, loading, updatevalue, updateFunction }) => {
    const dispatch = useDispatch();
    const fetchById = (id) => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getCustomerByIdApi({ id }),
                async (response) => {
                    setFields({ ...fields, ...response })
                    setLoading(false)
                },
                (err) => {
                    setFields({ ...fields, err })
                    setLoading(false)

                }
            )
        )
    }

    React.useEffect(() => {
        if (id) {
            fetchById(id)
        }
    }, [id]);

    return updatevalue === 2 && id ? updatevalue === 2 && (
        <TrialsUi
            onSubmit={(e) => updateFunction(e)}
            loading={loading}
            setFields={setFields}
            setLoading={setLoading}
            fields={fields}
            id={id}
        />
    ) :
        id ? (<CustomDialog
            id={"CustomerInformation"}
            title={"Update Customer"}
            closeText={"Close"}
            confirmText={"Update"}
            onSubmit={(e) => updateFunction(e)}
            loading={loading}
        >
            {updatevalue === 0 && <CustomerCreateUi setFields={setFields} fields={fields} loading={loading} setLoading={setLoading} />}
            {updatevalue === 1 && <AudioGram setFields={setFields} fields={fields} loading={loading} setLoading={setLoading} />}
            {updatevalue === 3 && <CustomerProductPurchaseUi setFields={setFields} fields={fields} loading={loading} setLoading={setLoading} />}
            {updatevalue === 4 && <CustomerProductReturnUi setFields={setFields} fields={fields} loading={loading} setLoading={setLoading} />}

        </CustomDialog >
        )
            :
            (
                <>
                    {!loading ? <Paper sx={{ height: "100%", padding: 4 }}>

                        {activeStep === 0 && <Box mb={4} p={2}>
                            <Typography variant='h3'>Add Customer</Typography>
                        </Box>}

                        <Stack sx={{ width: '100%', }} >

                            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Stack>


                        {activeStep === 0 && <CustomerCreateUi setFields={setFields} fields={fields} loading={loading} setLoading={setLoading} />}
                        {activeStep === 1 && <AudioGram setFields={setFields} fields={fields} loading={loading} setLoading={setLoading} />}
                        {activeStep === 2 && <TrialsUi setFields={setFields} fields={fields} loading={loading} setLoading={setLoading} />}
                        {activeStep === 3 && <CustomerProductPurchaseUi setFields={setFields} fields={fields} loading={loading} setLoading={setLoading} />}
                        <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end", gap: "10px", p: 2, alignItems: "center" }}>
                            {activeStep === 0 || activeStep !== 3 && <Button sx={{ width: "10vw" }} variant="contained" onClick={SkipSteps}>
                                <Typography variant='h4'>Skip</Typography>
                            </Button>}
                            <Button variant="contained" sx={{ width: "10vw" }} onClick={(e) => NextSteps(e)}>
                                <Typography variant='h4'>{activeStep === 3 || activeStep === 2 ? "Submit" : "Save"}</Typography>
                            </Button>
                        </Box>

                    </Paper>:<CenteredBox><CircularProgress/></CenteredBox>}
                </>)
}

export default StepperUi