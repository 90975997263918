import axios from "axios";
import { getFileHeaders, getHeaders } from "../utils/main";
import endpoints from "./endpoints";


export const importProductStockCsvApi = async data => {
    const callResponse = await axios({
      url: endpoints.createProductStock,
      method: "POST",
      // headers: getHeaders(),
      // headers: { "Content-Type": "application/json", ...getHeaders() },
      headers:getFileHeaders(),
        // responseType:"",
        data,
    })
      .then(response => response.data)
      .catch(err => err.response.data);
  
    return callResponse;
  };

    
export const fetchProductStockApi = async params => {
  const callResponse = await axios({
    url: endpoints.fetchProductStock,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const fetchProductStockCountApi = async params => {
  const callResponse = await axios({
    url: endpoints.fetchProductStockCounts,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const fetchFindByBoxIdApi = async params => {

  const callResponse = await axios({
      url: endpoints.fetchFindByBoxId,
      method: "get",
      headers: getHeaders(),
      params,
  })
      .then(response => response.data)
      .catch(err => err.response.data);

  return callResponse;
};

export const transferProductStockApi = async data => {

  const callResponse = await axios({
      url: endpoints.transferProductStock,
      method: "Patch",
      headers: getHeaders() ,
      data,
  })
      .then(response => response.data)
      .catch(err => err.response.data);

  return callResponse;
};
  export const fetchProductStockCsvApi = async data => {
    const callResponse = await axios({
      url: endpoints.createProductStock,
      method: "GET",
      // headers: getHeaders(),
      headers: { "Content-Type": "application/json", ...getHeaders() },

        // responseType:"",
        data,
    })
      .then(response => response.data)
      .catch(err => err.response.data);
  
    return callResponse;
  };
