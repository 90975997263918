import React, { useEffect, useState } from "react";
import CustomerInformation from "./CustomerInformation";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import loadingImg from "../../assets/images/loading.gif";
import { callApiAction } from "../../store/actions/commonAction";
import { useDispatch } from "react-redux";
import { getCustomerByIdApi } from "../../apis/customer.api";
import { Box } from "@mui/material";

const CustomerInformationController = ({ id }) => {
  const [params, setParams] = useState({});
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const fetchDetails = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getCustomerByIdApi({ id }),
        (response) => {
          setParams(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) {
      fetchDetails();
    }
  }, [id]);

  return (
    <CustomDialog id={"CustomerInformation"} title={`Customer Information`}>
      {!loading ? (
        <CustomerInformation params={params} />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={loadingImg} alt="Loading ..." />
        </Box>
      )}
    </CustomDialog>
  );
};

export default CustomerInformationController;
