import React, { useEffect, useMemo, useState } from "react";
import StoreStockUi from "./StoreStockUi";
import { INVENTORY_TYPE, SNACK_BAR_VARIETNS } from "../../../utils/constants";
import {
  fetchCountsApi,
  fetchLogsApi,
  fetchStockByIdApi,
} from "../../../apis/stock.api";
import { callApiAction } from "../../../store/actions/commonAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import { Box, IconButton, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { closeModal, openModal } from "../../../store/actions/modalAction";
import MessageDilog from "../../../components/MessageDilog";
import { fetchProductAction } from "../../../store/actions/setting.Action";
import { findObjectKeyByValue } from "../../../utils/main";

const ActionComponent = ({ params, setParams, deleteApi, deleted }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state);

  // const onEdit = () => {
  //   dispatch(
  //     openModal(
  //       <CustomerCreateController
  //         id={params._id}
  //         callBack={(response, updatedData) => {
  //           setParams({ ...params, ...updatedData });
  //         }}
  //       />,
  //       "sm"
  //     )
  //   );
  // };

  const deleteFun = async (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteApi({ id: params._id }),
        (response) => {
          setParams({});
          setLoading(false);
          dispatch(
            callSnackBar(
              `params.first_name + "Deleted Successfully"`,
              SNACK_BAR_VARIETNS.suceess
            )
          );
        },
        (err) => {
          setLoading(false);
        }
      )
    );
    dispatch(closeModal());
  };

  const onDelete = (e) => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={deleteFun}
          title="Alert!"
          message={`Are you sure you want to delete customer "${
            params.first_name || params.title
          }" ?`}
        />,
        "sm"
      )
    );
  };
  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      {/* <IconButton size="inherit" onClick={onEdit}>
          <Edit color="info" fontSize="inherit" />
        </IconButton> */}
      <IconButton disabled={loading} size="inherit" onClick={onDelete}>
        <Delete color="error" fontSize="inherit" />
      </IconButton>
    </Box>
  );
};

const StoreStockController = () => {
  const [buttonStatus, setButtonStatus] = useState(INVENTORY_TYPE.ALL);
  const [loading, setLoading] = useState(false);
  const [countLoading, setCountLoading] = useState(false);
  const dispatch = useDispatch();
  const [count, setCount] = useState([]);
  const fetchApi = fetchCountsApi;
  const [list, setList] = useState({});
  const { settings } = useSelector((state) => state);
  const [selectedButtonId, setSelectedButtonId] = useState(null);

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    sort: "",
    sortDirection: -1,
    searchable: ["product_name,box_id,product_code"],
    startDate: moment().startOf("day").valueOf(),
    endDate: moment().endOf("day").valueOf(),
  });

  const fetchCount = () => {
    setCountLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ inventory_type: buttonStatus }),
        (response) => {
          setCount(response);
          setCountLoading(false);
        },
        (err) => {
          setCountLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchCount();
  }, [filters, buttonStatus]);

  const columns = useMemo(() => {
    return [
      {
        id: 1,
        minWidth: "50px",
        fieldName: "box_id",
        label: "Box Id",
        align: "left",
        sort: true,
        renderValue: (params) => params.box_id,
      },
      {
        id: 2,
        minWidth: "50px",
        fieldName: "product_name",
        label: "Product Name",
        align: "left",
        sort: true,
        renderValue: (params) => params?.product_id?.product_name,
      },
      {
        id: 3,
        fieldName: "product_code",
        label: "Product Code",
        align: "left",
        sort: true,
        renderValue: (params) => params?.product_id?.product_code,
      },
      {
        id: 4,
        fieldName: "category",
        label: "Type",
        align: "left",
        sort: true,
        renderValue: (params) =>
          findObjectKeyByValue(params?.category, INVENTORY_TYPE),
      },
    ];
  }, [dispatch, buttonStatus]);

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await fetchStockByIdApi({
            inventory_type: buttonStatus,
            ...filters,
            product_id: selectedButtonId,
          }),
        (response) => {
          setList(response);
          console.log("thias si sofj;joqerhf4p3rfn", response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (count.length > 0) {
      if (!selectedButtonId) {
        setSelectedButtonId(count[0]._id);
      }
    }
  }, [count]);

  useEffect(() => {
    fetchList();
  }, [filters, buttonStatus, selectedButtonId]);

  return (
    <StoreStockUi
      setButtonStatus={setButtonStatus}
      buttonStatus={buttonStatus}
      loading={loading}
      filters={filters}
      setFilters={setFilters}
      count={count}
      columns={columns}
      list={list}
      setSelectedButtonId={setSelectedButtonId}
      selectedButtonId={selectedButtonId}
      countLoading={countLoading}
    />
  );
};

export default StoreStockController;
