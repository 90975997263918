import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import StockListUi from "./StockUi";
import { fetchWareHouseStock } from "../../../apis/stock.api";
import { callApiAction } from "../../../store/actions/commonAction";
const StockController = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const dispatch = useDispatch();

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchWareHouseStock(),
        (response) => {
          console.log("this is stock res", response);
          setData(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, []);

  return <StockListUi data={data} />;
};
export default memo(StockController);
