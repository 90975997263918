import axios from "axios";
import endpoints from "./endpoints";
import { getHeaders } from "../utils/main";



export const getCustomerApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchCustomer,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const FetchCustomerOrSalesApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.FetchCustomerOrSales,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getCustomerCountApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.customerCountApi,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getCustomerByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchCustomerById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

;


export const addCustomerApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.createCustomer,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateCustomerApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.updateCustomer,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};


export const deleteCustomerApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.deleteCustomer,
    method: "PATCH",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};


