
import { Autocomplete, Box, Checkbox, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Input, ListItem, MenuItem, Radio, RadioGroup, Switch, Table, Typography } from "@mui/material"
import { memo, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { closeModal } from "../../store/actions/modalAction"
import SubmitButton from "../../components/button/SubmitButton"
import CustomInput from "../../components/inputs/CustomInputs"
import { InputComponent, StyledSearchBar } from "../../components/inputs/SearchBar"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { fetchProductCategoryApi, fetchSoldTypes } from "../../apis/inventory.api"
import { updateInventoryListAction } from "../../store/actions/invenotryListAction"
import { CUSTOMER_TYPE, INVOICE_TYPE, USER_ROLES } from "../../utils/constants"
import { getUserApi } from "../../apis/user.api"
import { Remove } from "@mui/icons-material"
const ChannelSaleCreateUi = ({ title, onSubmit, boxes, onSelectRange, fields, setFields, loading, dispatchBoxes, isFree }) => {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(closeModal())
  }
  return <>

    <DialogTitle variant="h3">{boxes?.length != 1 && 'Select Devices to'} {title}
      <Typography variant="h6">{boxes?.length != 1 && ('Maximum 500 Devices')} </Typography>
      <Typography variant="h6" color="red">{fields.err} </Typography>
    </DialogTitle>

    <DialogContent sx={{ minHeight: '100px', display: "flex", position: "relative" }}>

      <Box sx={{ minHeight: '100px', width: "100%", display: "flex" }}>
        {boxes?.length != 1 &&
          <Box sx={{ position: "relaive", overflowY: 'scroll', overflow: 'hidden', display: 'flex', flexDirection: 'column', flex: 1.3 }} >
            {<Box onSubmit={onSelectRange} component={"form"} sx={{ border: 1, p: 1, borderRadius: 1, position: 'sticky', top: '0px', background: "light", padding: 2, }}>
              <Typography variant="h5" mb={2}>Select Devices Range</Typography>
              <Box sx={{ display: "flex", alignItems: "center", padding: '10px' }}>
                <CustomInput disabled={loading} placeholder={"Start"} value={fields.startBoxId} onChange={(e) => { setFields({ ...fields, err: '', startBoxId: e.target.value }) }} />
                <Remove sx={{ fontSize: '12px' }} />
                <CustomInput disabled={loading} placeholder={"End"} value={fields.endBoxId} onChange={(e) => { setFields({ ...fields, err: '', endBoxId: e.target.value }) }} />
              </Box>
              <SubmitButton loading={loading} disabled={loading} type="submit" sx={{ mt: 1 }} title="Find Boxes" />

            </Box>}


            {<Box sx={{ flex: 1 }}>

              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} mt={1}>
                <Typography variant="h5">Devices</Typography>
                <Typography variant="h5">{boxes?.length}</Typography>
              </Box>
              {boxes?.length == 0 && <Typography variant="h6" mt={3} mb={3} textAlign="center">No data</Typography>}
              {boxes?.length > 0 &&

                <Box sx={{ position: "relative", overflowY: 'scroll' }}>
                  {boxes?.length > 0 ? (
                    <Box>

                      {boxes.map((box, index) => (

                        <BoxItem
                          key={box.box_id}
                          title={box.box_id}
                          amount={box.amount}
                          category={box.category}
                          selected={box.selected}
                          dispatchBoxes={dispatchBoxes}
                        />
                      ))}
                    </Box>
                  ) : (
                    <Typography variant="h6" mt={3} mb={3} textAlign="center">
                      No data available. Please try again with different IDs.
                    </Typography>
                  )}
                </Box>

              }
            </Box>}
          </Box>}





        <Box sx={{ border: 1, padding: '10px 10px 10px 10px', borderRadius: 1, ml: 4, flexDirection: "column", overflowY: "scroll", flex: 1, display: 'flex', maxHeight: "100%", overflow: 'hidden' }}>
          <Typography variant="h5" mb={5}>Details</Typography>

          {<AsyncDropDown
            defaultVal={
              fields.child_id
                ? {
                  _id: fields.child_id?._id,
                  name: fields.store?.name,
                }
                : null
            }
            lazyFun={async (para) =>
              await getUserApi({ ...para, isDropDown: true, inOut: true })
            }
            OptionComponent={({ option, ...rest }) => {
              return <ListItem {...rest}>{option.name}</ListItem>;
            }}
            value={fields?.child_id}
            onChange={async (changedVal) => {
              setFields({
                ...fields,
                child_id: changedVal ? changedVal._id : null,
              });
            }}
            titleKey={"name"}
            valueKey={"_id"}
            InputComponent={(params) => (
              <StyledSearchBar
                placeholder={"Transfer To*"}
                {...params}
                margin="none"
              />
            )}
          />}
          {/* {<Box sx={{ mt: '4px' }}><CustomInput type="number" min={0} label="Unit Price(₹)*" onChange={(e) => setFields({ ...fields, amount: e.target.value })} /></Box>}
          {<Box sx={{}}><CustomInput type="text" label="Invoice number*" onChange={(e) => setFields({ ...fields, invoice_no: e.target.value })} /></Box>}

          {
            <Box sx={{}}>
              <Autocomplete
                disabled={loading}
                options={[
                  { label: "Trial", value: INVOICE_TYPE.TRIALS },
                  { label: "Purchased", value: INVOICE_TYPE.PURCHESED },
                  { label: "Return", value: INVOICE_TYPE.RETURN },
                ]}
                getOptionLabel={(option) => option.label}
                value={
                  fields.category !== undefined
                    ? [
                      { label: "Trial", value: INVOICE_TYPE.TRIALS },
                      { label: "Purchased", value: INVOICE_TYPE.PURCHESED },
                      { label: "Return", value: INVOICE_TYPE.RETURN },
                    ].find((option) => option.value === fields.category) || null
                    : null
                }
                onChange={(e, newValue) => {
                  setFields({ ...fields, category: newValue?.value || '' });
                }}
                renderInput={(params) => (
                  <CustomInput
                    {...params}
                    label="Product Category*"
                    placeholder="select Category*"
                    sx={{ height: '56px' }}
                  />
                )}
              />
            </Box>} */}

          {user.data.role === USER_ROLES.WEHEAR_ADMIN  &&
            <Box sx={{ mt: '4px' }}><CustomInput type="number" min={0} label="Unit Price(₹)*" onChange={(e) => setFields({ ...fields, amount: e.target.value })} /></Box>}
          {<Box sx={{}}><CustomInput type="text" label="Invoice number*" onChange={(e) => setFields({ ...fields, invoice_no: e.target.value })} /></Box>}





          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              border: "1px solid #101536",
              borderRadius: "5px",
              padding: "8px",
              marginTop: 1,
              overflow: "hidden",
              maxWidth: "100%",
            }}
          >
            <FormLabel
              sx={{
                fontSize: { xs: "12px", sm: "14px", md: "16px" },
                whiteSpace: "nowrap",
                flexShrink: 0,
              }}
            >
              Category*
            </FormLabel>

            <Box
              sx={{
                width: "1px",
                height: "30px",
                backgroundColor: "navy",
                flexShrink: 0,
              }}
            />

            <RadioGroup
              row
              value={fields.category}
              onChange={(e) =>
                setFields({
                  ...fields,
                  err: "",
                  category: e.target.value,
                })
              }
              name="category"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: "8px", sm: "0px" },
                flexWrap: "nowrap",
                flexGrow: 1,
                overflow: "hidden",
                "& .MuiFormControlLabel-root": {

                },
                "& .MuiFormControlLabel-label": {
                  fontSize: { xs: "10px", sm: "12px", md: "16px" },
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                },
              }}
            >
              <FormControlLabel
                value={INVOICE_TYPE.TRIALS}
                control={<Radio sx={{ transform: { xs: "scale(0.8)", sm: "scale(1)" } }} />}
                label="Trials"
              />
              <FormControlLabel
                value={INVOICE_TYPE.PURCHESED}
                control={<Radio sx={{ transform: { xs: "scale(0.8)", sm: "scale(1)" } }} />}
                label="Purchased"
              />
            </RadioGroup>
          </Box>


        </Box>


      </Box>
    </DialogContent>
    <DialogActions>
      <SubmitButton onClick={handleClose} title={'close'}></SubmitButton>
      <SubmitButton loading={loading} disabled={loading} onClick={onSubmit ? onSubmit : handleClose} title={
        user.data.role === USER_ROLES.WEHEAR_ADMIN
          ? "Transfer to Warehouse"
          : user.data.role === USER_ROLES.LENSKART_ADMIN
            ? "Transfer to Store"
            : "Sell"
      } ></SubmitButton>    </DialogActions>


  </>
}

const BoxItem = ({ title, selected, dispatchBoxes }) => {
  console.log(`Rendering BoxItem: ${title}, selected: ${selected}`);
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Checkbox
        checked={selected}
        onChange={(e) => {
          const isChecked = e.target.checked;
          dispatchBoxes(updateInventoryListAction(title, "selected", isChecked));
        }}
      />

      <Box sx={{ m: 2, color: "black" }}>{title}</Box>
    </Box>
  );
};

export default ChannelSaleCreateUi;