import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CardHeader,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { StyledSearchBar } from "../../../components/inputs/SearchBar";
import SearchIcon from "../../../assets/images/searchIcon.svg";
import { ExpandMore } from "@mui/icons-material";
import CustomizedAccordions from "../../../components/AccordionComponent";
import loadingImg from "../../../assets/images/loading.gif";

const StoreDetails = ({ list }) => {
  console.log("this is riya", list);
  return (
    <Box>
      {list?.map((item) => (
        <Box mb={2}>
          <CustomizedAccordions item={item} />
        </Box>
      ))}
    </Box>
  );
};

const StoreUi = ({ list, filters, setFilters, loading }) => {
  return (
    <>
      <Box
        p={3}
        sx={{ marginTop: "20px", backgroundColor: "white", minHeight: "55vh" }}
      >
        <Box>
          <TextField
            id="outlined-start-adornment"
            fullWidth
            onChange={(e) => {
              setFilters({ ...filters, search: e.target.value });
            }}
            placeholder="Search Area/store"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "40vh",
            }}
          >
            <img src={loadingImg} alt="Loading.." />
          </Box>
        ) : list.length > 0 ? (
          <Box sx={{ marginTop: "20px" }}>
            <StoreDetails list={list} />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "40vh",
            }}
          >
            <Typography sx={{ fontWeight: "700" }}>No Store Found.</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default StoreUi;
