import axios from "axios";
import { getHeaders } from "../utils/main";
import endpoints from "./endpoints";

export const DownloadLedgerApi = async params => {
    const callResponse = await axios({
      url: endpoints.ledgerPdf,
      method: "GET",
      headers: getHeaders(),
      responseType: "blob",
      params,
    })
      .then(response => response.data)
      .catch(err => err.response.data);
  
    return callResponse;
  };