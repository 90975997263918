import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Box,
  Card,
  CardContent,
  Divider,
  styled,
  Button,
  Grid2,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../store/actions/modalAction";
import WareHouseProductWiseDataController from "./WareHouseProductWiseDataController";
import { USER_ROLES } from "../../../utils/constants";

const InfoCard = ({ title, total, trialCount, sellCount, onSubmit, }) => {
  return (
    <Card
      onClick={onSubmit}
      sx={{
        width: "18vw",
        borderRadius: "8px",
        backgroundColor: "#ECFDF5", // Slightly lighter green
        border: "1px solid #28B867", // Softer border color
        mb: 4,
        cursor: "pointer",
      }}
    >
      <Box mt={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // Center the title
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            color="text.primary"
            sx={{ fontWeight: "bold", fontSize: "20px" }}
          >
            {title}
          </Typography>
        </Box>

        <Box
          p={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#FFFFFF",
            borderRadius: "4px",
            // padding: "4px 16px 4px 16px",
            border: "1px solid #D5F6E3",
            marginTop: 2, // Add spacing between the title and details box
          }}
        >
          <Box sx={{ textAlign: "center", flex: 1 }}>
            <Typography
              variant="body2" // Slightly smaller text
              color="#28B867"
            >
              Saleable
            </Typography>
            <Typography variant="h2" sx={{ fontWeight: "bold" }}>
              {sellCount}
            </Typography>
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#D1E7DD", marginX: 1 }} // Subtle divider
          />
          <Box sx={{ textAlign: "center", flex: 1 }}>
            <Typography variant="body2" color="#28B867">
              Trial
            </Typography>
            <Typography variant="h2" sx={{ fontWeight: "bold" }}>
              {trialCount}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

const BoxContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap", // Allow wrapping to the next row
  gap: 2,
  width: "100%",
  justifyContent: "space-between", // Space between items
  marginTop: theme.spacing(2),
  padding: 2,
}));

const WareHouseUi = ({ title, list, onChannelSaleClick, isStore }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state)

  const onView = (id) => {
    dispatch(
      openModal(
        <WareHouseProductWiseDataController parent_id={isStore} id={id} />,
        "md",
        false,
        "productstock"
      )
    );
  };


  return (
    <Box
      p={3}
      sx={{ marginTop: "20px", backgroundColor: "white", minHeight: "55vh" }}
    >
      {user.data.role != USER_ROLES.WEHEAR_ADMIN && <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {" "}
        <Typography sx={{ color: "#101536", fontWeight: "700" }}>
          {title}
        </Typography>{" "}
        {!isStore && (
          <Button
            sx={{ width: "25%" }}
            onClick={onChannelSaleClick}
            variant="contained"
          >
            <Typography>Transfer to store</Typography>
          </Button>
        )}
      </Box>}
      <Grid2 size={list?.length / 3} justifyContent={"space-between"}>
        <BoxContainer>
          {list?.map((temp) => (
            <InfoCard
              onSubmit={() => {
                onView(temp._id);
              }}
              title={temp?.product_name}
              trialCount={temp.inventory_type1_count ?? 0}
              sellCount={temp.inventory_type2_count ?? 0}
            />
          ))}
        </BoxContainer>
      </Grid2>
    </Box>
  );
};

export default WareHouseUi;