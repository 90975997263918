import React, { useEffect, useState } from "react";
import { fetchProductAction } from "../../store/actions/setting.Action";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItem,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import {
  CenteredBox,
  FilterTitleBox,
} from "../../components/layouts/OneViewBox";
import {
  CUSTOMER_TYPE,
  STATUS_COLOR_TYPE,
  STOCKLOG_STATUS,
  USER_ROLES,
} from "../../utils/constants";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { fetchProductApi } from "../../apis/product.api";
import { StyledSearchBar } from "../../components/inputs/SearchBar";
import ClickButton from "../../components/button/ClickButton";
import CustomInput from "../../components/inputs/CustomInputs";
import moment from "moment";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { getSalesPersonApi } from "../../apis/salePerson.api";
import { getUserApi } from "../../apis/user.api";

const CustomerProductPurchaseUi = ({
  fields,
  setFields,
  setLoading,
  loading,
}) => {
  const { settings, user } = useSelector((state) => state);
  const dispatch = useDispatch();
  console.log("object this is list", fields);

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["product_name"],
    sort: "createdAt",
    sortDirection: -1,
  });

  const getProductList = () => {
    if (
      !settings.product_data ||
      settings.product_data.length === 0 ||
      JSON.stringify(filters) != JSON.stringify(settings.product_filters)
    ) {
      dispatch(fetchProductAction(filters));
    }
  };

  useEffect(() => {
    getProductList();
  }, [filters]);

  const Colordata = (list) => {
    setFields((prevFields) => ({
      ...prevFields,
      sold_details: list._id,
      err: "",
    }));
  };

  return (
    <>
      <Box mt={4} p={4} height={"55.5vh"}>
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            <Box p={2}>
              <Typography variant="body2" color="red">
                {fields.err}
              </Typography>
            </Box>
            {/* <Box p={2} mt={2} sx={{ display: "flex", flexDirection: "row", width: "100%", gap: "10px" }}>
                        {settings?.product_data.result?.map((list) => (
                            <Paper
                                key={list._id}
                                onClick={() => Colordata(list)}
                                sx={{
                                    backgroundColor: STATUS_COLOR_TYPE(list._id === fields.sold_details),
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "15vh",
                                    width: "15vw",
                                    borderRadius: "8px",
                                    cursor: "pointer",
                                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                                }}
                            >
                                <FilterTitleBox>
                                    <Box

                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}
                                    >
                                        <Typography variant="h4" color={list._id !== fields.sold_details ? "#1D013B" : "white"}>{list?.product_name}</Typography>
                                    </Box>
                                </FilterTitleBox>
                            </Paper>
                        ))}
                    </Box> */}

            <Box>
              <Box>
                <Typography variant="h3">Purchased Product :</Typography>
              </Box>

              <Box mt={2} p={2}>
                <AsyncDropDown
                  defaultVal={
                    fields?.sold_details?.product?._id &&
                    fields?.sold_details?.product?.product_name
                      ? {
                          _id: fields.sold_details.product._id,
                          product_name:
                            fields.sold_details.product.product_name,
                        }
                      : null
                  }
                  lazyFun={async (para) =>
                    await fetchProductApi({ ...para, allStatus: true })
                  }
                  OptionComponent={({ option, ...rest }) => {
                    return <ListItem {...rest}>{option.product_name}</ListItem>;
                  }}
                  // value={fields?.sold_details?.product ?? ""}
                  onChange={(changedVal) => {
                    setFields((prevFields) => ({
                      ...prevFields,
                      sold_details: {
                        ...prevFields.sold_details,
                        product: changedVal._id ? changedVal._id : [],
                      },
                      err: "",
                    }));
                  }}
                  titleKey={"product_name"}
                  valueKey={"_id"}
                  InputComponent={(params) => (
                    <StyledSearchBar
                      placeholder={"Select Product*"}
                      {...params}
                      margin="none"
                    />
                  )}
                />
              </Box>

              {/* <Box mt={4}>
                            <Typography variant='h3'>
                                Other Details :
                            </Typography>
                        </Box> */}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Box width={"100%"} p={2}>
                  <CustomInput
                    disabled={loading}
                    value={fields?.sold_details?.box_id}
                    onChange={(e) =>
                      setFields({
                        err: "",
                        ...fields,
                        sold_details: {
                          ...fields.sold_details,
                          box_id: e.target.value,
                        },
                      })
                    }
                    type="text"
                    label={"Enter Box Id*"}
                  />
                </Box>

                <Box p={2} width={"100%"}>
                  <DesktopDatePicker
                    // sx={{ width: "18vw", height: "100%" }}
                    disableFuture
                    inputFormat="DD/MM/yyyy"
                    renderInput={(props) => <CustomInput {...props} />}
                    label={"Trial Date"}
                    disabled={loading}
                    value={fields?.sold_details?.date}
                    onChange={(e) => {
                      setFields({
                        ...fields,
                        sold_details: {
                          ...fields.sold_details,
                          date: moment(e).toISOString(),
                        },
                      });
                    }}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Box width={"100%"} p={2}>
                  <CustomInput
                    disabled={loading}
                    value={fields?.sold_details?.amount}
                    onChange={(e) =>
                      setFields({
                        err: "",
                        ...fields,
                        sold_details: {
                          ...fields.sold_details,
                          amount: e.target.value,
                        },
                      })
                    }
                    type="text"
                    label={"Amount*"}
                  />
                </Box>

                {user.data.role !== USER_ROLES.SALES_PERSON && (
                  <Box width={"100%"} mt={2} p={2}>
                    <AsyncDropDown
                      defaultVal={
                        fields.sold_details?.sales_person?._id
                          ? {
                              _id: fields?.sold_details?.sales_person?._id,
                              name: fields.sold_details?.sales_person?.name,
                            }
                          : null
                      }
                      lazyFun={async (para) => await getUserApi({ ...para })}
                      OptionComponent={({ option, ...rest }) => {
                        return <ListItem {...rest}>{option.name}</ListItem>;
                      }}
                      value={fields?.sold_details?.sales_person ?? []}
                      onChange={(changedVal) => {
                        setFields((prevFields) => ({
                          ...prevFields,
                          sold_details: {
                            ...prevFields.sold_details,
                            sales_person: changedVal._id ? changedVal._id : [],
                          },
                          err: "",
                        }));
                      }}
                      titleKey={"name"}
                      valueKey={"_id"}
                      InputComponent={(params) => (
                        <StyledSearchBar
                          placeholder={"Select Sales Person*"}
                          {...params}
                          margin="none"
                        />
                      )}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default CustomerProductPurchaseUi;
