import axios from "axios";
import { getHeaders } from "../utils/main";
import endpoints from "./endpoints";

export const updateStockApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.updateStock,
    method: "PATCH",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const fetchStockApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchStock,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const fetchStockByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchStockById,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const fetchStockByStoreIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchStockByStoreId,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const fetchWareHouseStock = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchwareHouseStock,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const fetchWareHouseData = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchwareHouseStockData,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const fetchLogsApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.stockLogsApi,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};
export const CreatePaymentStatusApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.CreatePaymentStatus,
    method: "post",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}));

  return callResponse;
};

export const fetchCountsApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.stockCountApi,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};
