import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TimelineIcon from '@mui/icons-material/Timeline';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { IconButton, List, ListItemButton, ListSubheader, Popover, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
// import { USER_UPDATE_TYPE } from "../../../utils/constants";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import StepperController from "./StepperController";
import { Edit } from '@mui/icons-material';
import MODULES from '../../utils/module.constant';
import { USER_ROLES } from '../../utils/constants';

const UpdateCustomerButton = ({ params, setParams ,valueref}) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state);

    const update = (value) => {
        dispatch(
            openModal(
                <StepperController
                    id={params._id}
                    updatevalue={value}
                    valueref={valueref}
                // callBack={(response, updatedData) => {
                //   setParams({ ...params, ...updatedData });
                // }}
                />,
                "md", false, "CustomerInformation"
            )
        );
    };

    return <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
            <>
                <Tooltip title={"Update Details"}>
                    <IconButton {...bindTrigger(popupState)}>
                        <Edit color="info" />
                    </IconButton>
                </Tooltip>
                <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    {/* <Typography sx={{ p: 2 }}> */}
                    <List
                        sx={{
                            width: "100%",
                            maxWidth: "100%",
                            bgcolor: "background.paper",
                        }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader
                                component="div"
                                variant="subtitle1"
                                //sx={(theme) => ({ color: theme.palette.text.primary })}
                                color="text.primary"
                                id="nested-list-subheader"
                            >
                                <Typography variant="h5">
                                    Update Details
                                </Typography>
                            </ListSubheader>
                        }
                    >
                        {user.data.role !== USER_ROLES.AUDIOLOGIST && <ListItemButton onClick={() => update(0)}>

                            <GroupAddIcon variant={"primary.main"} />
                            &nbsp;&nbsp;Customer Details

                        </ListItemButton>}
                        {/* <ListItemButton onClick={updatePassword}>

                                <LockOpen variant={"primary.main"} />
                                &nbsp;&nbsp;Update Password

                            </ListItemButton> */}
                        {(user.data.role === USER_ROLES.AUDIOLOGIST || user.data.role === USER_ROLES.LENSKART_STORE) && MODULES.CUSTOMER_MODULE && <ListItemButton onClick={() => update(1)}>

                            <TimelineIcon variant={"primary.main"} />
                            &nbsp;&nbsp;Audiogram Details

                        </ListItemButton>}

                        {user.data.role !== USER_ROLES.AUDIOLOGIST && <ListItemButton onClick={() => update(2)}>

                            <VideoLabelIcon
                                variant={"primary.main"}
                            />
                            &nbsp;&nbsp;Product Trial Details

                        </ListItemButton>}

                        {user.data.role !== USER_ROLES.AUDIOLOGIST && <ListItemButton onClick={() => update(3)}>

                            <ShoppingCartIcon variant={"primary.main"} />
                            &nbsp;&nbsp;Product Purchase Details

                        </ListItemButton>}

                        {/* <ListItemButton onClick={()=>update(4)}>

                                <LocalShippingIcon variant={"primary.main"} />
                                &nbsp;&nbsp;Product Return

                            </ListItemButton> */}
                    </List>
                    {/* </Typography> */}
                </Popover>
            </>
        )}
    </PopupState >

}
export default UpdateCustomerButton