import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { findNameByRole, findObjectKeyByValue } from "../../utils/main";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useMemo } from "react";
import {
  ChangeCircle,
  Delete,
  Edit,
  LockOpen,
  Undo,
} from "@mui/icons-material";
import MessageDilog from "../../components/MessageDilog";
import { GENDER, SNACK_BAR_VARIETNS, USER_ROLES } from "../../utils/constants";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { deleteSalesPersonApi, getSalesPersonApi } from "../../apis/salesPerson.api";
import SalesPersonCreateController from "./SalesPersonCreateController";
import SalesPersonListUi from "./SalesPersonListUi"
import CreateController from "./SalesPersonCreateController";
import { getUserApi } from "../../apis/user.api";


const ActionComponent = ({ params, setParams, deleteApi, deleted }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { user } = useSelector(state => state);
  console.log("users.........", user)

  const onEdit = () => {
    dispatch(
      openModal(
        <CreateController id={params._id} title={" Sales Person"}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "sm"
      )
    );
  };

  const deleteFun = async (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteApi({ id: params._id }),
        (response) => {
          setParams({});
          setLoading(false);
          dispatch(
            callSnackBar(
              params.first_name + "Deleted Successfully",
              SNACK_BAR_VARIETNS.suceess
            )
          );
        },
        (err) => {
          setLoading(false);
        }
      )
    );
    dispatch(closeModal());
  };

  const onDelete = (e) => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={deleteFun}
          title="Alert!"
          message={`Are you sure you want to delete customer "${params.name || params.title}" ?`}
        />,
        "sm"
      )
    );
  };
  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      {user.data.role === USER_ROLES.LENSKART_ADMIN && <IconButton size="inherit" onClick={onEdit}>
        <Edit color="info" fontSize="inherit" />
      </IconButton>}
      {user.data.role === USER_ROLES.LENSKART_ADMIN && <IconButton disabled={loading} size="inherit" onClick={onDelete}>
        <Delete color="error" fontSize="inherit" />
      </IconButton>}
    </Box>
  );
};

const SalesPersonListController = () => {
  const dispatch = useDispatch();

  const title = "Sales Person";
  const fetchApi = getSalesPersonApi;
  const deleteApi = deleteSalesPersonApi;
  const { user } = useSelector((state) => state);

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    searchable: ["name", "email"],
    search: "",
    sort: "",
    sortDirection: -1,
    deleted: null,
  });

  const columns = useMemo(() => {
    const baseColumns = [
      {
        id: 1,
        minWidth: "50px",
        fieldName: "name",
        label: "Name",
        align: "left",
        sort: true,
      },
      {
        id: 2,
        fieldName: "sales_person_code",
        label: "Code",
        align: "left",
      },
      {
        id: 3,
        fieldName: "store_id",
        label: "Store",
        align: "left",
        renderValue: (params) => params.store_id.name,
      },
    ];

    if (user.data.role === USER_ROLES.LENSKART_ADMIN) {
      baseColumns.push({
        id: 5,
        fieldName: "",
        label: "Action",
        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            deleteApi={deleteApi}
            params={params}
            setParams={setParams}
          />
        ),  
      });
    }

    return baseColumns;
  }, [dispatch, user.data.role]);

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters, store_id: user.data._id }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  const onCreateBtnClick = () => {
    dispatch(
      openModal(
        <CreateController title={" Sales Person"}
          callBack={async () => {
            fetchList();
          }}
        />,
        "sm"
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <SalesPersonListUi
        title={title}
        onCreateBtnClick={onCreateBtnClick}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default SalesPersonListController;
