import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { FilterTitleBox } from "../../components/layouts/OneViewBox";
import { findObjectKeyByValue, titleCase } from "../../utils/main";
import {
  BUTTON_STATUS_COLOR_TYPE,
  BUTTON_TYPE,
  STATUS,
  STATUS_COLOR_TYPE,
} from "../../utils/constants";
import { formatNumberCustomPattern } from "../../utils/helper";

const ButtonComponentsUi = ({
  count,
  onSubmit,
  Title,
  ButtonGroup,
  colorType,
  STATUSWiseData,
}) => {
  return (
    <>
      {!ButtonGroup ? (
        <Card
          onClick={onSubmit}
          sx={{
            backgroundColor: STATUS_COLOR_TYPE(colorType),
            width: "18vw",
            borderRadius: "8px",
            border: colorType ? "3px solid #28B867" : "1px solid #020043",
            cursor: "pointer",
          }}
        >
          <Box mt={3}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  color: colorType ? "#28B867" : "text.primary",
                }}
              >
                {Title}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "4px",
                marginTop: 2,
              }}
            >
              <Box sx={{ textAlign: "center", flex: 1 }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontWeight: "medium",
                    color: colorType ? "#28B867" : "text.primary",
                  }}
                >
                  {formatNumberCustomPattern(count)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Card>
      ) : (
        <Button
          onClick={onSubmit}
          variant={BUTTON_STATUS_COLOR_TYPE(STATUSWiseData)}
          sx={{
            height: "5vh",
            width: "100%",
            borderRadius: "8px",
            backgroundColor: STATUS_COLOR_TYPE(STATUSWiseData),
            border: STATUSWiseData ? "2px solid #28B867" : "1px solid #020043",
            padding: "5px",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              fontSize: "20px",
              color: STATUSWiseData ? "#28B867" : "text.primary",
            }}
          >
            {Title}
          </Typography>
        </Button>
      )}
    </>
  );
};

export default ButtonComponentsUi;
