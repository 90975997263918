import { INVENTORY_TYPE } from "./constants"

const MODULES = {
    DASHBOARD_MODULE: 1,
    PRODUCT_MODULE:2,
    INVENTORY_MODULE:10,
    USER_MODULE:3,
    STOCK_MODULE:4,
    PRODUCT_COLOR_MODULE:5,
    CUSTOMER_MODULE:7,
    PRODUCT_TYPE_MODULE:6,
    SALES_PERSON_MODULE:12,
    LEDGER_MODULE:8,
    SALES:9,
    // SALES_PERSON:10,
    // AUDIOLOGIST:11
}


export default MODULES
