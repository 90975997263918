import React, { useEffect, useState } from "react";
import StoreUi from "./StoreUi";
import { useDispatch } from "react-redux";
import { getUserApi } from "../../../apis/user.api";
import { callApiAction } from "../../../store/actions/commonAction";
import { Box, Button, ButtonGroup } from "@mui/material";
import WareHouseController from "./WareHouseController";
import ButtonComponentsUi from "../../../components/button/ButtonComponentsUi";
import WareHouseUi from "./WareHouseUi";

const StoreController = () => {
  const dispatch = useDispatch();
  const fetchApi = getUserApi;
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    searchable: ["name", "address"],
    search: "",
    role: "",
    sort: "",
    sortDirection: -1,
    deleted: null,
  });
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const [tab, setTab] = useState(0);

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters, inOut: true, isStoreData: true }),
        (response) => {
          setList(response.result);
          console.log("this is list", response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <Box mt={3} sx={{ height: "100%" }}>
      <Box>
        <ButtonGroup
          sx={{ color: "#101536" }}
          fullWidth
          size="large"
          aria-label="Large button group"
        >
          <Button
            sx={tab == 1 && { backgroundColor: "white" }}
            variant={tab == 0 ? "contained" : "outlined"}
            onClick={() => setTab(0)}
            fullWidth
          >
            ALL
          </Button>
          <Button
            sx={tab == 0 && { backgroundColor: "white" }}
            variant={tab == 1 ? "contained" : "outlined"}
            onClick={() => setTab(1)}
            fullWidth
          >
            Store wise
          </Button>
        </ButtonGroup>
      </Box>
      {tab == 0 && (
        <WareHouseController isStore={true} title={"All store stock"} />
      )}
      {tab == 1 && (
        <StoreUi
          list={list}
          filters={filters}
          setFilters={setFilters}
          loading={loading}
        />
      )}
    </Box>
  );
};

export default StoreController;
