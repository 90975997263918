import {
  Badge,
  Box,
  Collapse,
  Fab,
  IconButton,
  Paper,
  SwipeableDrawer,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Link, Outlet, useLocation } from "react-router-dom";

import { useEffect, useMemo, useState } from "react";
import { memo } from "react";
import AppModeLabel from "../../texts/AppModeLabel";
import PopUpModal from "../../Modal";
import Logo from "./Logo";
import Header from "./Header";
import Navbar from "./Navbar";
// import  from "../../../pages/main/"

const navBarOuterStyle = (theme) => ({ height: "100%", position: "relative" });
const containerStyle = (theme) => ({
  height: "100%",
  width: "100%",
  display: "flex",
  overflow: "hidden",
  background: theme.palette.light.main,
});
const collapsableContainerStyle = (theme) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
});
const navbarInnerStyle = (theme) => ({
  width: "260px",
  height:"auto",
  background: "white",
  height: "100%",
  overflowY: "auto",
  borderRadius: "0px",
  borderRight: "1px solid #DDDDDD",
  scrollBarWidth: "none",
  " -ms-overflow-style": "none",
  "::-webkit-scrollbar": {
    display: "none",
  },
});

const logoContainer = (theme) => ({
  width: "100%",
  position: "sticky",
  top: "0px",
  padding:3,
  height: "auto",
  borderBottom: "1px solid #DDDDDD",
  zIndex: "1",
  backgroundColor: "white",
});
const rightContainerStyle = (theme) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  height: "100vh",
  overflow: "auto",
  scrollBarWidth: "none",
});
const WorkAreaComponent = styled(Box)(({ theme, hideOverflow }) => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  flex: 1,
  overflow: hideOverflow ? "hidden" : "unset",
  background: theme.palette.grey.main,
  borderTopLeftRadius: theme.shape.borderRadius * 1,
}));
const workAreaStyle = (theme) => ({});

const AppContainer = ({ hideOverflow, ...props }) => {
  const [navBar, setNavbar] = useState(true);
  const location = useLocation();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <>
      {process.env.REACT_APP_APP_MODE != "production" && <AppModeLabel />}

      <PopUpModal />

      <Box sx={containerStyle}>
        {!isSmallScreen && (
          <Collapse
            orientation="horizontal"
            in={navBar}
            sx={collapsableContainerStyle}
          >
            <Paper elevation={0} sx={navbarInnerStyle}>
              <Box pt={3} mb={6} sx={logoContainer}>
                <Logo />
              </Box>
              <Navbar />
            </Paper>
          </Collapse>
        )}
        {isSmallScreen && (
          <SwipeableDrawer
            anchor={"left"}
            open={navBar}
            onClose={() => setNavbar(false)}
            onOpen={() => setNavbar(true)}
          >
            <Paper elevation={2} sx={navbarInnerStyle}>
              <Box sx={logoContainer} mb={4}>
                <Logo sx={{ zIndex: 1 }} />
              </Box>
              <Box sx={{ position: "relative", zIndex: 1 }}>
                <Navbar />
              </Box>
            </Paper>
          </SwipeableDrawer>
        )}
        <Box sx={rightContainerStyle}>
          <Header open={navBar} setOpen={setNavbar} />
          <WorkAreaComponent p={3} hideOverflow={hideOverflow}>
            {props.children}
            <Outlet />
          </WorkAreaComponent>
        </Box>
      </Box>
    </>
  );
};
export default memo(AppContainer);
