import { Box } from "@mui/material";
import { CenteredBox } from "./boxes";
import LogoImage from "./../../../assets/images/logo1.svg";
const Logo = ({ ...rest }) => {
  return (
    <CenteredBox {...rest}>
      <img
        src={LogoImage}
        style={{
          width: "100%",
          maxWidth: "140px",
          objectFit: "contain",
          paddingTop: "10px",
        }}
        alt="Lenskart IMS"
      />
    </CenteredBox>
  );
};
export default Logo;
