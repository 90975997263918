import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "../../components/tables/DataTable";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import {
  FilterTitleBox,
  FiltersBox,
} from "../../components/layouts/OneViewBox";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
import AsyncSearchBar from "../../components/inputs/AsyncSearchBar";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import CreateProductUi from "./CreateProductUi";
import CreateProductController from "./CreateProductController";
import { USER_ROLES } from "../../utils/constants";
import DashboardProductCounts from "../dashboard/DashboardProductCounts";

const ProductListUi = ({
  columns,
  list,
  filters,
  setFilters,
  loading,
  title,
  categoryType,
  setCategoryType,
}) => {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"))
  const id = "product";
  const onCreateBtnClick = () => {
    dispatch(openModal(<CreateProductController />, "sm", false, id));
  };
  return (
    <>
      <Box>
        <Paper
          sx={{
            width: "100%",
            padding: 6,
          }}
        >
          <Box mb={4} display="flex" flexDirection="column" gap={isSmallScreen ? 2 : 4}>
            <FilterTitleBox>
              <Typography
                variant={isSmallScreen ? "h2" : "h3"}
                textAlign={isSmallScreen ? "left" : "left"}
                marginBottom={isSmallScreen ? '5px' : '0'}
                color={"#000"}>
                Product
              </Typography>
              {/* <DashboardProductCounts filters={filters} setFilters={setFilters} /> */}

              <Box sx={{ display: 'flex', justifyContent: isSmallScreen ? "flex-end" : "flex-end", }}>
                <Button
                  onClick={onCreateBtnClick}
                  sx={{
                    width: isSmallScreen ? "40%" : "16vw",
                    height: "6vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  variant="contained"
                >
                  <AddIcon /> &nbsp;
                  <Typography variant="h4" sx={{ display: "flex" }}>
                    Add Product
                  </Typography>
                </Button>
              </Box>
            </FilterTitleBox>


            <Box
              display="flex"
              justifyContent={isSmallScreen ? "flex-end" : "flex-end"}
              alignItems="center"
              mt={1}
            >


              <Box>
                <PaddingBoxInDesktop
                  sx={{
                    display: "flex",
                    width: isSmallScreen ? "50vw" : "20vw",
                  }}
                >
                  <AsyncSearchBar
                    fullWidth
                    title="Search By Product Name"
                    size="small"
                    placeholder={"Search By Product Name"}
                    defaultValue={filters?.search}
                    onChange={(changedVal) =>
                      setFilters({
                        ...filters, pageNo: 1,
                        pageSize: 10, search: changedVal
                      })
                    }
                  />
                </PaddingBoxInDesktop>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              minHeight: "40vh",
            }}
          >
            <DataTable
              columns={columns}
              rows={list?.result ? list?.result : []}
              count={list?.total ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default ProductListUi;
