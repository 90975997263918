import { useCallback, useMemo, useReducer, useState } from "react"
import { useParams } from "react-router-dom"
// import importDeviceSheetReducer from "../../store/reducers/importDeviceSheetReducer"

import Papa from "papaparse";
// import { addDataToSheetAction, setErrorsInDeviceList } from "../../store/actions/importDeviceSheetAction";
import useValidate from "../../store/hooks/useValidator";
import { useDispatch, useSelector } from "react-redux";
// import { registerBoxes } from "../../../apis/inventory.api";
// import { fetchLifeTimeStockCountAction } from "../../store/actions/lifeTimeStockCountAction";
// import { fetchInventoryCountAction } from "../../store/actions/inventoryCountAction";
// import { getDateFiltersTime } from "../../../utils/helper";
import MessageDilog from "../../components/MessageDilog";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { callApiAction } from "../../store/actions/commonAction";
import ImportCsvCreateUi from "./ImportCsvCreateUi";
import { importProductStockCsvApi } from "../../apis/productStock.api";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";

const ImportCsvCreateController = ({ }) => {

    const params = useParams()
    const validate = useValidate()
    const dispatch = useDispatch()
    const [err, setErr] = useState("");
    const [hasErr, setHasErr] = useState(false);
    const [data, setData] = useState([]);
    const [file, setFile] = useState(null);

    // const { inventoryCount } = useSelector(state => state)
    // const [fields, setFields] = useState({
    //     // defaultAmount: 10000,
    //     err: '',
    //     // invoiceId: '',
    //     // product: null,
    //     // usercode: params.usercode
    //     box_id:"",
    //     product_id:"",
    //     color_id:""
    // })
    const fields = [
        "box_id",
        "product_id",
        "color_id"
    ]
    const [loading, setLoading] = useState()

    // const [sheet, dispatchSheet] = useReducer(importDeviceSheetReducer, [])
    // console.log("SHEET",sheet)
    const onFileSelect = (e) => {

        setLoading(true)
        const file = e.target.files[0]
        setFile(e.target.files[0])
        const reader = new FileReader();
        console.log("files",file)
        console.log("Readerss",reader)

        reader.onload = async ({ target }) => {
            console.log("TARGETs",target)
            const csv = Papa.parse(target.result, { header: true });
            console.log("CSV",csv)
            const parsedData = csv?.data;
            console.log("Parsed Data",parsedData)
            const columns = Object.keys(parsedData[0]);
            console.log("columns",columns)
            let hasColumnError = false;

                console.log("fieldss",fields)
      for (let field of fields) {
        console.log("fileds llllll",field)
        if (!columns.includes(field)) {
          setErr(`Field with field name ${field} doesnot exist.`);
          setLoading(false);
          hasColumnError = true;
          break;
        }
      }

      if (parsedData && Array.isArray(parsedData)) {
        const tempData = parsedData?.map((parsedrow) => {
            console.log("parsedDATA",parsedrow)
          if (Object.keys(parsedrow).length === columns.length) {
            console.log("object key parse drow",Object.keys(parsedrow).length)
            console.log("columns length",columns.length)
            const rowData = {
              hasError: false,
              data: {},
            };
            const row = {};
            for (let cell in parsedrow) {
                console.log("CELL",cell)
              if (
                (cell == "box_id" || cell == "product_id" || cell == "color_id") &&
                parsedrow[cell] == ""
              ) {
                rowData["hasError"] = true;
                setHasErr(true);
                row[cell] = {
                  value: parsedrow[cell],
                  err: "This cell should be non empty.",
                };
              } else if (
                cell != "box_id" &&
                cell != "color_id" && cell != "product_id"&&
                (!cell || !parsedrow[cell] || isNaN(parsedrow[cell]))
              ) {
                row[cell] = {
                  value: 0,
                  err: "",
                };
              } else
                row[cell] = {
                  value: parsedrow[cell],
                  err: "",
                };
            }
            rowData["data"] = row;
            console.log("ROW",row)
            console.log("row data",rowData)
            return rowData;
          }
        });
        console.log("temp datass",tempData)
        setData(tempData);
        console.log("Temp Data",data)
      } else {
        setErr("cant read file.");
      }

      setLoading(false);
    };
        reader.readAsText(file);
        console.log("readersss",reader)
    }


    
    const createFunction = async () => {
        // const validationResponse = validate(validationSchemaForCreate)

        // if (validationResponse === true) {
            const formData = new FormData();
            formData.append("file", file);
            // formData.append("date", date);
            if (file != null ) {

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await importProductStockCsvApi(formData),
                    async (response) => {
                        console.log("Fieldss",response)
                        // await callBack()
                        setLoading(false)
                        dispatch(callSnackBar("User Created Successfully", SNACK_BAR_VARIETNS.suceess))
                        dispatch(closeModal())
                    },
                    (err) => {
                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
                        setLoading(false)
                        // setFields({ ...fields, err })
                    }
                )
            )
        }
        }
        //  else {
        //     // setFields({ ...fields, 'err': validationResponse })
        
    
    const onSubmit = async (e) => {
        e.preventDefault()
        // if (id)
        //     updateFunction()
        // else
        createFunction()

    }
  //  console.log(sheet)
    return <ImportCsvCreateUi loading={loading} onFileSelect={onFileSelect} data={data} hasError={hasErr} onSubmit={onSubmit} fields={fields}  />

}
export default ImportCsvCreateController