import { Box, Typography } from "@mui/material";
import React from "react";
import LKAdminStockController from "./LKAdmin/StockController";
import { useSelector } from "react-redux";
import { USER_ROLES } from "../../utils/constants";
import LKStoreStockController from "./LKStore/StoreStockController";


const Stock = () => {
  const { user } = useSelector((state) => state);
  return (
    <Box overflow={"auto"} sx={{ scrollbarWidth: "none" }}>
      {(user.data.role == USER_ROLES.LENSKART_ADMIN || user.data.role == USER_ROLES.WEHEAR_ADMIN) && (
        <LKAdminStockController />
      )}
      {(user.data.role == USER_ROLES.LENSKART_STORE || user.data.role === USER_ROLES.SALES_PERSON) && (
        <LKStoreStockController />
      )}
    </Box>
  );
};

export default Stock;