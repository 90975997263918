import { Autocomplete, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo } from "react"
import { useDispatch, useSelector } from 'react-redux'
import SubmitButton from "../../components/button/SubmitButton"
import CustomInput from "../../components/inputs/CustomInputs"
import { closeModal } from "../../store/actions/modalAction"
import { AUS_STATES, USER_ROLES } from "../../utils/constants"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getOpenSolarUserApi } from "../../apis/user.api"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { DesktopDatePicker } from "@mui/x-date-pickers"
import moment from "moment"



const ResetPasswordUi = ({ title, isInstaller, isUpdate, fields, setFields, loading, onSubmit }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog

            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${title}`}
            closeText="Close"
            confirmText={`Reset`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    <CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.password}
                        onChange={(e) => setFields({ ...fields, err: '', password: e.target.value })}
                        type="password"
                        label={"Password*"}

                    />
                </>}

        </CustomDialog>
        {/* <Box component="form" sx={{ display: "flex", flexDirection: "column", overflowY: "auto" }} maxHeight="100%" onSubmit={onSubmit} >
            <DialogTitle variant="h6">{isUpdate ? "Update " : "Create "}{title}
                <Box component={'div'} ><Typography variant="body2" color={'red'}>{fields.err} </Typography></Box>
            </DialogTitle>
            <DialogContent>

                <CustomInput
                    disabled={loading}
                    value={fields.name}
                    onChange={(e) => setFields({ ...fields, err: '', name: e.target.value })}
                    type="text"
                    label={"Name*"}

                />

                <CustomInput
                    disabled={loading}
                    value={fields.email}
                    onChange={(e) => setFields({ ...fields, err: '', email: e.target.value })}
                    type="text"
                    label={"Email*"}

                />
                <CustomInput
                    disabled={loading}
                    value={fields.password}
                    onChange={(e) => setFields({ ...fields, err: '', password: e.target.value })}

                    type="password"
                    label={"Password*"}

                />


                <Autocomplete
                    disableClearable
                    autoFocus={false}
                    value={findNameByRole(fields.role)}
                    id="user-role-id"
                    name="user-role-id"
                    onChange={(e, newVal) => {
                        setFields({ ...fields, role: newVal ? newVal._id : null })
                    }}
                    options={[...Object.keys(USER_ROLES).map((key) => ({ label: titleCase(key), _id: USER_ROLES[key] }))]}
                    sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                    renderInput={(params) => <CustomInput placeholder="Select Role*" {...params} label="Select Role*" />}
                />

                <AsyncDropDown
                    id="open solar user"
                    name="open solar user"
                    lazyFun={getOpenSolarUserApi}
                    label="Open Solar User"
                    OptionComponent={({ option, ...rest }) => {
                        return <MenuItem {...rest}>{option.display}</MenuItem>
                    }}
                    onChange={async (changedVal) => { }}
                    titleKey={'display'}
                    valueKey={"id"}
                />
            </DialogContent>
            <DialogActions   >
                <SubmitButton variant='outlined' onClick={handleClose} title={'close'}></SubmitButton>
                <SubmitButton loading={loading} type="submit" title={isUpdate ? "Update" : "Create"} ></SubmitButton>
            </DialogActions>
        </Box> */}

    </>
}
export default memo(ResetPasswordUi)