import { Box, Fade, Grid, Paper, Slide, TextField, Typography, styled, useMediaQuery } from '@mui/material';
import OneViewBox, { CenteredBox } from '../../components/layouts/OneViewBox';
import { center } from '../../assets/css/theme/common';
import Logo from '../../assets/images/IMS_logo.svg'
import BrandLogo from '../../assets/images/brands_logo.svg'
import { Link, useLocation } from 'react-router-dom';
import { useRef } from 'react';
import { BorderTop } from '@mui/icons-material';



const PageContainer = styled(Box)(({ theme }) => ({
    height: "100vh",
    width: "100vw",
    background: `linear-gradient(55deg, #020043 25%, #28B867 100%)`,
    display: "flex",
    justifyContent: "flex-start",
    position: "relative",
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    },
}));


const RightSection = styled(Box)(({ theme }) => ({
    width: "40%",
    height: "100%",
    background: "#FFFFFF",
    borderLeft: "2px solid #28B867",
    borderTopLeftRadius: "24px",
    borderBottomLeftRadius: "24px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    right: 0,
    [theme.breakpoints.down('md')]: {
        width: "100%", // Full width on small screens
        height: "70%", // Adjust height
        position: "relative",
        borderLeft: "none", // Remove left border
        // BorderTop:'10px solid #28B867',
        borderTop: "2px solid #28B867",
        borderTopLeftRadius: "24px", // Add top-left radius
        borderTopRightRadius: "24px", // Add top-right radius
        borderBottomLeftRadius: "0", // Remove bottom-left radius
        borderBottomRightRadius: "0", // No bottom-right radius
    },
}));

const LogoContainer = styled(Box)(({ theme }) => ({
    width: "45%",
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "flex-end", // Center vertically
    flexDirection: "column",
    color: "#FFFFFF",
    marginTop: "0",

    [theme.breakpoints.down('md')]: {
        width: "100%", // Full width for smaller screens
        height: "50%", // Center within new container height
        marginTop: "10px",
        alignItems: 'center',
        justifyContent: 'center'



    },
}));



const LoginForm = styled(Box)(({ theme }) => ({
    width: "30vw",
    textAlign: "center",
}));
const MainUi = ({ onSubmit, ...props }) => {
    const ref = useRef();
    const location = useLocation();
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"))

    return (
        <PageContainer>
            <LogoContainer>
                <img
                    src={Logo}
                    alt="IMS Logo"
                    style={{
                        width: isSmallScreen ? "40%" : "70%",

                        height: "30vh",
                        marginBottom: "20px",
                        marginLeft: isSmallScreen ? '30px' : "0px"
                    }}
                />
            </LogoContainer>

            <RightSection>
                <img src={BrandLogo} alt="Brand Logo" />
                <LoginForm component="form" onSubmit={onSubmit}>
                    <Typography
                        variant="h1"
                        gutterBottom
                        style={{
                            color: "#393939",
                            fontWeight: "bold",
                            textAlign: "center",
                            marginBottom: "1rem",
                        }}
                    >
                        Welcome back!
                    </Typography>
                    <Typography sx={{ fontSize: "20px", color: "#393939" }}>
                        Please enter your details.
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            overflow: "hidden",
                        }}
                        ref={ref}
                    >
                        <Slide
                            timeout={400}
                            direction={
                                location.pathname !== "/sign-up"
                                    ? "right"
                                    : "left"
                            }
                            in={true}
                            container={ref.current}
                        >
                            <Box sx={{ width: "100%" }}>{props.children}</Box>
                        </Slide>
                    </Box>
                    <Box textAlign="center" marginTop="2rem"></Box>
                </LoginForm>
            </RightSection>
        </PageContainer>
    );
};

export default MainUi;