import React, { useEffect, useState } from "react";
import WareHouseUi from "./WareHouseUi";
import { useDispatch } from "react-redux";
import { fetchWareHouseData } from "../../../apis/stock.api";
import { callApiAction } from "../../../store/actions/commonAction";
import ChannelSaleCreateController from "../../StockProduct/ChannelSaleCreateController";
import { openModal } from "../../../store/actions/modalAction";

const WareHouseController = ({ title, isStore }) => {
  const dispatch = useDispatch();
  const fetchApi = fetchWareHouseData;
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    searchable: ["name", "address"],
    search: "",
    role: "",
    sort: "",
    sortDirection: -1,
    deleted: null,
  });
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const onChannelSaleClick = () => {
    dispatch(openModal(<ChannelSaleCreateController />, "md", false));
  };

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ isStore }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <>
      <WareHouseUi
        title={title}
        isStore={isStore}
        list={list}
        onChannelSaleClick={onChannelSaleClick}
      />
    </>
  );
};

export default WareHouseController;
