import {
  Box,
  Paper,
  styled,
  Typography,
  ButtonGroup,
  Grid2,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import React, { useState } from "react";
import ButtonComponentsUi from "../../components/button/ButtonComponentsUi";
import { SALEABLE_TYPE } from "../../utils/constants";
import { findObjectKeyByValue, titleCase } from "../../utils/main";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector";
import AsyncSearchBar from "../../components/inputs/AsyncSearchBar";
import DataTable from "../../components/tables/DataTable";
import { useSelector } from "react-redux";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
  padding: "8px",
  marginTop: "12px",
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const SalesListUi = ({
  setButtonStatus,
  buttonStatus,
  selectedButtonId,
  setSelectedButtonId,
  columns,
  list,
  filters,
  setFilters,
  itemType,
  loading,
  count,
  countLoading,
}) => {
  const user = useSelector((state) => state.user);
  const { settings } = useSelector((state) => state);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const issmSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Paper sx={{ padding: 4, width: "100%" }}>
      {/* <Box mt={4} gap={4} p={2} display={"flex"} justifyContent={"space-between"} flexDirection={"row"}>
                <Box width={"25vw"}>
                    <ButtonComponentsUi count={(count[0]?.count ?? 0) + (count[1]?.count ?? 0)} Title={"All"} />
                </Box>
                <Box width={"25vw"}>
                    <ButtonComponentsUi count={count[0]?.count ?? 0} onSubmit={() => setButtonStatus(SALEABLE_TYPE.SOLD)} colorType={buttonStatus === SALEABLE_TYPE.SOLD} Title={titleCase(findObjectKeyByValue(SALEABLE_TYPE.SOLD, SALEABLE_TYPE))} />
                </Box>
                <Box width={"25vw"}>
                    <ButtonComponentsUi count={count[1]?.count ?? 0} onSubmit={() => setButtonStatus(SALEABLE_TYPE.TRIAL)} colorType={buttonStatus === SALEABLE_TYPE.TRIAL} Title={titleCase(findObjectKeyByValue(SALEABLE_TYPE.TRIAL, SALEABLE_TYPE))} />
                </Box>
                
                </Box> */}

      <Box>
        <Typography variant="h3">Sales</Typography>
      </Box>

      <FilterTitleBox>
        <Box>
          <PaddingBoxInDesktop
            sx={{
              display: "flex",
              width: "30vw",
              flexDirection: "flex-start",
            }}
          >
            <TimeRangeSelector
              onChange={(val) => setFilters({ ...filters, ...val })}
            />
          </PaddingBoxInDesktop>
        </Box>

        <Box width={"50%"}>
          <ButtonGroup fullWidth sx={{ flexDirection: "row" }}>
            <ButtonComponentsUi
              width={500}
              count={
                (count[0]?.count ?? 0) +
                (count[1]?.count ?? 0) +
                (count[2]?.count ?? 0)
              }
              onSubmit={() => setButtonStatus(SALEABLE_TYPE.ALL)}
              ButtonGroup
              STATUSWiseData={buttonStatus === SALEABLE_TYPE.ALL}
              Title={titleCase(
                findObjectKeyByValue(SALEABLE_TYPE.ALL, SALEABLE_TYPE)
              )}
            />
            <ButtonComponentsUi
              count={count[0]?.count ?? 0}
              onSubmit={() => setButtonStatus(SALEABLE_TYPE.SOLD)}
              ButtonGroup
              STATUSWiseData={buttonStatus === SALEABLE_TYPE.SOLD}
              Title={titleCase(
                findObjectKeyByValue(SALEABLE_TYPE.SOLD, SALEABLE_TYPE)
              )}
            />
            <ButtonComponentsUi
              count={count[1]?.count ?? 0}
              onSubmit={() => setButtonStatus(SALEABLE_TYPE.TRIAL)}
              ButtonGroup
              STATUSWiseData={buttonStatus === SALEABLE_TYPE.TRIAL}
              Title={titleCase(
                findObjectKeyByValue(SALEABLE_TYPE.TRIAL, SALEABLE_TYPE)
              )}
            />
          </ButtonGroup>
        </Box>
      </FilterTitleBox>

      {
        countLoading ? (
          <Box
            mt={4}
            mb={4}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              height: "15vh",
            }}
          >
            {[0, 1, 2, 3].map((row) => (
              <Skeleton
                key={row}
                variant="rectangular"
                width={"24%"}
                height={120}
                sx={{
                  borderRadius: "8px",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
              />
            ))}
          </Box>
        ) : (
          // <Box mt={4} gap={4} p={2} display={"flex"} justifyContent={"space-between"} flexDirection={"row"}>
          <Grid2
            container
            mt={4}
            p={2}
            size={12}
            justifyContent={"space-between"}
          >
            {" "}
            {count?.map((listData) => (
              <>
                <Grid2
                  display={"flex"}
                  mb={4}
                  width={"18vw"}
                  flexDirection={"row"}
                  flexWrap={"wrap"}
                  size={listData.length / 3}
                >
                  <ButtonComponentsUi
                    count={listData?.count ?? 0}
                    onSubmit={() => {
                      // setButtonStatus(SALEABLE_TYPE.ALL);
                      setSelectedButtonId(listData._id);
                    }}
                    colorType={selectedButtonId === listData._id}
                    Title={listData?.product_name}
                  />
                </Grid2>
              </>
            ))}
          </Grid2>
        )
        // </Box>
      }

      {/* <Box p={2} mt={4} width={"100%"} display={"flex"} justifyContent={"flex-end"}>


                <Box>

                    <PaddingBoxInDesktop
                        sx={{
                            display: "flex",
                            width: "20vw",
                            flexDirection: "flex-start",
                        }}
                    >

                    </PaddingBoxInDesktop>
                </Box>
                <Box>

                            <PaddingBoxInDesktop
                                sx={{
                                    display: "flex",
                                    width: "20vw",
                                    flexDirection: "flex-start",
                                }}
                            >
                                <AsyncSearchBar
                                    fullWidth
                                    title="Search By Name"
                                    size="small"
                                    placeholder={"Search By Name"}
                                    defaultValue={filters?.search}
                                    onChange={(changedVal) =>
                                        setFilters({
                                            ...filters, pageNo: 1,
                                            pageSize: 10, search: changedVal
                                        })
                                    }
                                />
                            </PaddingBoxInDesktop>
                        </Box>
                    </Box>

                    <Box p={2} sx={{ minHeight: "40vh" }}>
                        <DataTable
                            columns={columns}
                            rows={list ? list : []}
                            count={list?.length ?? 0}
                            filters={filters}
                            setFilters={setFilters}
                            loading={loading}
                        />
                    </PaddingBoxInDesktop>
                </Box>
            </Box> */}

      <Box p={2} sx={{ minHeight: "40vh" }}>
        <DataTable
          columns={columns}
          rows={list?.result ? list?.result : []}
          count={list?.total ?? 0}
          filters={filters}
          setFilters={setFilters}
          loading={loading}
        />
      </Box>
    </Paper>
  );
};

export default SalesListUi;
