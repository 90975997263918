import * as React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Box, Button, Paper, Typography } from "@mui/material";
import CustomerCreateController from "./CustomerCreateController";
import AudioGram from "./AudioGram";
import moment from "moment";
import TimelineIcon from "@mui/icons-material/Timeline";
import CustomerCreateUi from "./CustomerCreateUi";
import useValidate from "../../store/hooks/useValidator";
import { callSnackBar } from "../../store/actions/snackbarAction";
import {
  CUSTOMER_TYPE,
  INVENTORY_TYPE,
  SNACK_BAR_VARIETNS,
  USER_ROLES,
} from "../../utils/constants";
import { callApiAction } from "../../store/actions/commonAction";
import { useDispatch, useSelector } from "react-redux";
import {
  addCustomerApi,
  getCustomerByIdApi,
  updateCustomerApi,
} from "../../apis/customer.api";
import { useNavigate } from "react-router";
import StepperUi from "./StepperUi";
import { closeModal } from "../../store/actions/modalAction";

export default function StepperController({ id, updatevalue,valueref }) {
 
  const [activeStep, setActiveStep] = useState(0);
  const validate = useValidate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(state => state)
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    err: "",
    id,
    first_name: "",
    middle_name: "",
    last_name: "",

    phone: "",
    email: "",
    dob: moment(),
    gender: null,
    address: "",
    state: "",
    city: "",
    zip_code: null,
    suggested_product: [],
    suggested_product_id: "",
    store_id: "",
    customer_type: "",

    sold_details:
    {
      box_id: "",
      date: moment(),
      amount: "",
      status: "",
      product: "",
      sales_person: "",
    }
    ,
    level_of_hearing_loss: null,
    type_of_hearing_loss: null,

    trial: [
      {
        product: "",
        box_id: "",
        date: moment(),
        feedback: ""
      }],
    allowed_ac: false,
    lf1: 0,
    lf2: 0,
    lf3: 0,
    lf4: 0,
    lf5: 0,
    lf6: 0,
    lf7: 0,
    lf8: 0,

    rf1: 0,
    rf2: 0,
    rf3: 0,
    rf4: 0,
    rf5: 0,
    rf6: 0,
    rf7: 0,
    rf8: 0,

    allowed_bc: false,

    blf1: 0,
    blf2: 0,
    blf3: 0,
    blf4: 0,
    blf5: 0,
    blf6: 0,
    blf7: 0,
    blf8: 0,

    brf1: 0,
    brf2: 0,
    brf3: 0,
    brf4: 0,
    brf5: 0,
    brf6: 0,
    brf7: 0,
    brf8: 0,

    allowed_ucl: false,
    ulf1: 0,
    ulf2: 0,
    ulf3: 0,
    ulf4: 0,
    ulf5: 0,
    ulf6: 0,
    ulf7: 0,
    ulf8: 0,

    urf1: 0,
    urf2: 0,
    urf3: 0,
    urf4: 0,
    urf5: 0,
    urf6: 0,
    urf7: 0,
    urf8: 0,

    allowed_mcl: false,
    mlf1: 0,
    mlf2: 0,
    mlf3: 0,
    mlf4: 0,
    mlf5: 0,
    mlf6: 0,
    mlf7: 0,
    mlf8: 0,

    mrf1: 0,
    mrf2: 0,
    mrf3: 0,
    mrf4: 0,
    mrf5: 0,
    mrf6: 0,
    mrf7: 0,
    mrf8: 0,
  });

  const validationSchema = React.useMemo(
    () => [
      {
        required: true,
        value: fields.first_name,
        field: "First name",
      },
      {
        required: true,
        value: fields.phone,
        field: "Phone no ",
      },
      {
        required: true,
        value: fields.email,
        field: "email",
        isEmail: true,
      },

      {
        required: true,
        value: fields.dob,
        field: "DOB",
      },
      {
        required: true,
        value: fields.address,
        field: "Address",
      },
      {
        required: true,
        value: fields.state,
        field: "State",
      },
      {
        required: true,
        value: fields.city,
        field: "City",
      },
      {
        required: true,
        value: fields.zip_code,
        field: "Zip Code",
      },

      {
        // required: true,
        value: fields.store_id,
        field: "Store Id",
      },

      {
        required: true,
        value: fields.gender,
        field: "Gender",
      },
    ],
    [fields]
  );

  const validationSchemaAudiograms = React.useMemo(
    () => [
      {
        required: true,
        value: fields.lf1 == 0 ? true : true,
        field: "lf1",
      },
      {
        required: true,
        value: fields.lf2 == 0 ? true : true,
        field: "lf2",
      },
      {
        required: true,
        value: fields.lf3 == 0 ? true : true,
        field: "lf3",
      },
      {
        required: true,
        value: fields.lf4 == 0 ? true : true,
        field: "lf4",
      },
      {
        required: true,
        value: fields.lf5 == 0 ? true : true,
        field: "lf5",
      },
      {
        required: true,
        value: fields.lf6 == 0 ? true : true,
        field: "lf6",
      },
      {
        required: true,
        value: fields.lf7 == 0 ? true : true,
        field: "lf7",
      },
      // {
      //     required: true,
      //     value: fields.lf8,
      //     field: 'lf8',
      // },
      {
        required: true,
        value: fields.rf1  == 0 ? true : true,
        field: "rf1",
      },
      {
        required: true,
        value: fields.rf2 == 0 ? true : true,
        field: "rf2",
      },
      {
        required: true,
        value: fields.rf3 == 0 ? true : true,
        field: "rf3",
      },
      {
        required: true,
        value: fields.rf4 == 0 ? true : true,
        field: "rf4",
      },
      {
        required: true,
        value: fields.rf5 == 0 ? true : true,
        field: "rf5",
      },
      {
        required: true,
        value: fields.rf6 == 0 ? true : true,
        field: "rf6",
      },
      {
        required: true,
        value: fields.rf7 == 0 ? true : true,
        field: "rf7",
      },
      // {
      //     required: true,
      //     value: fields.rf8,
      //     field: 'rf8',
      // },
      {
        required: true,
        value: fields.blf1 == 0 ? true : true,
        field: "blf1",
      },
      {
        required: true,
        value: fields.blf2 == 0 ? true : true,
        field: "blf2",
      },
      {
        required: true,
        value: fields.blf3 == 0 ? true : true,
        field: "blf3",
      },
      {
        required: true,
        value: fields.blf4 == 0 ? true : true,
        field: "blf4",
      },
      {
        required: true,
        value: fields.blf5 == 0 ? true : true,
        field: "blf5",
      },
      {
        required: true,
        value: fields.blf6 == 0 ? true : true,
        field: "blf6",
      },
      {
        required: true,
        value: fields.blf7 == 0 ? true : true,
        field: "blf7",
      },
      // {
      //     required: true,
      //     value: fields.blf8,
      //     field: 'blf8',
      // },
      {
        required: true,
        value: fields.brf1 == 0 ? true : true,
        field: "brf1",
      },
      {
        required: true,
        value: fields.brf2 == 0 ? true : true,
        field: "brf2",
      },
      {
        required: true,
        value: fields.brf3 == 0 ? true : true,
        field: "brf3",
      },
      {
        required: true,
        value: fields.brf4 == 0 ? true : true,
        field: "brf4",
      },
      {
        required: true,
        value: fields.brf5 == 0 ? true : true,
        field: "brf5",
      },
      {
        required: true,
        value: fields.brf6 == 0 ? true : true,
        field: "brf6",
      },
      {
        required: true,
        value: fields.brf7 == 0 ? true : true,
        field: "brf7",
      },
      {
        required: true,
        value: fields.level_of_hearing_loss,
        field: "Level Of Hearing Loss",
      },
      {
        required: true,
        value: fields.type_of_hearing_loss,
        field: "Type Of Hearing Loss",
      },
      {
        required: true,
        value: fields.suggested_product.length > 0 && fields.suggested_product,
        field: "Suggested Product",
      },
    ],
    [fields]
  );

  const validationSchemaTrial = React.useMemo(
    () => [
      {
        required: true,
        value: fields.trial[0]?.box_id,
        field: "Box Id",
      },
      // {
      //   required: true,
      //   value: fields?.trial?.date,
      //   field: "Date ",
      // },
      {
        required: true,
        value: fields?.trial[0]?.feedback,
        field: " Feedback",
      },
      {
        required: true,
        value: fields.trial[0]?.product,
        field: "Trial Product",
      },
      // {
      //   required: true,
      //   value: user.data.role == USER_ROLES.SALES_PERSON ? user.data._id : fields?.sold_details?.sales_person,
      //   field: "Select Sales Person",
      // },
    ],
    [fields]
  );

  const validationSchemaPurchase = React.useMemo(
    () => [
      {
        required: true,
        value: fields.sold_details?.box_id,
        field: "Box Id",
      },
      {
        required: true,
        value: fields?.sold_details?.date,
        field: " Date",
      },
      {
        required: true,
        value: fields?.sold_details?.amount,
        field: " Amount",
      },
      {
        required: true,
        value: fields.sold_details.product,
        field: "Trial Product",
      },
      {
        required: true,
        value: user.data.role == USER_ROLES.SALES_PERSON ? user.data._id : fields?.sold_details?.sales_person,
        field: "Select Sales Person",
      },
    ],
    [fields]
  );

  const CUSTOMER_TYPE_DATA = (activeStep) => {
    switch (activeStep) {
      case 2:
        return valueref == CUSTOMER_TYPE.PURCHASED ? valueref : CUSTOMER_TYPE.TRIALS;
      case 3:
        return CUSTOMER_TYPE.PURCHASED;
      case 4:
        return CUSTOMER_TYPE.RETURN;
      default:
        return valueref ? valueref : CUSTOMER_TYPE.TESTED;
    }
  };
  const datavalue = updatevalue ? updatevalue : activeStep;
  const customer_type = CUSTOMER_TYPE_DATA(datavalue);


  const createFunction = async (activeStep) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await addCustomerApi({
            ...fields,
            customer_type,
            sold_details: { ...fields.sold_details, status: customer_type },
          }),
        async (response) => {
          setLoading(false);
          dispatch(
            callSnackBar(" Created Successfully", SNACK_BAR_VARIETNS.suceess)
          );
          navigate("/customer/");
        },
        (err) => {
          setLoading(false);
          dispatch(callSnackBar("Error", SNACK_BAR_VARIETNS.error));
          setFields({ ...fields, err });
        }
      )
    );
  };
  const updateFunction = async (e) => {
    e.preventDefault();
    const schemas = [
      validationSchema,
      validationSchemaAudiograms,
      validationSchemaTrial,
      validationSchemaPurchase,
    ];
    const validationResponse = validate(schemas[updatevalue]);
    const temp = fields.suggested_product.map((item) => item?._id);

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () =>
            await updateCustomerApi({
              ...fields,
              id,

              customer_type,
              suggested_product: fields?.suggested_product_id
                ? fields?.suggested_product
                : temp,
              sold_details: {
                ...fields?.sold_details,
                sales_person: user.data.role == USER_ROLES.SALES_PERSON ? user.data._id : (fields?.sold_details?.sales_person?._id ? fields?.sold_details?.sales_person?._id : fields?.sold_details?.sales_person),
                product: fields?.sold_details?.product?._id ? fields?.sold_details?.product?._id : fields?.sold_details?.product,
                status: customer_type,
              },
            }),
          async (response) => {
            setLoading(false);
            dispatch(
              callSnackBar(" update Successfully", SNACK_BAR_VARIETNS.suceess)
            );
            dispatch(closeModal("CustomerInformation"));
          },
          (err) => {
            setLoading(false);
            dispatch(callSnackBar("Error", SNACK_BAR_VARIETNS.error));
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const NextSteps = async (e) => {
    e.preventDefault();

    const schemas = [
      validationSchema,
      validationSchemaAudiograms,
      validationSchemaTrial,
      validationSchemaPurchase,
    ];
    const validationResponse = validate(schemas[activeStep]);

    if (validationResponse === true) {
      if (activeStep === 0 || activeStep === 3 || activeStep === 2) {
        try {
          await createFunction(activeStep);
        } catch (error) {
          setFields({ ...fields, err: "An error occurred. Please try again." });
        }
      } else {
        setActiveStep(activeStep + 1);
      }
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const SkipSteps = () => {
    setActiveStep(activeStep + 1);
    setFields({ ...fields, sold_details: {} });
  };

  // const fetchById = (id) => {
  //     setLoading(true)
  //     dispatch(
  //         callApiAction(
  //             async () => await getCustomerByIdApi({ id }),
  //             async (response) => {
  //                 setFields({ ...fields, ...response })
  //                 setLoading(false)
  //             },
  //             (err) => {
  //                 setFields({ ...fields, err })
  //                 setLoading(false)

  //             }
  //         )
  //     )
  // }

  // React.useEffect(() => {
  //     if (id && fields.trial.length < 0  ) {
  //         setFields({
  //           ...fields,
  //           trial: [
  //             {
  //               product: "",
  //               box_id: "",
  //               date: moment(),
  //               feedback: ""
  //             }]
  //         })  

  //     }

  // }, [fields.trial.length]);


  return (
    <StepperUi
      SkipSteps={SkipSteps}
      loading={loading}
      setLoading={setLoading}
      NextSteps={NextSteps}
      fields={fields}
      setFields={setFields}
      activeStep={activeStep}
      id={id}
      updatevalue={updatevalue}
      updateFunction={updateFunction}
    />
  );
}