import axios from "axios";
import endpoints from "./endpoints";
import { getHeaders } from "../utils/main";



export const getSalesPersonApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchSalesPerson,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};




export const getSalesPersonByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchSalesPersonById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

;


export const addSalesPersonApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.createSalesPerson,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateSalesPersonApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.updateSalesPerson,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};


export const deleteSalesPersonApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.deleteSalesPerson,
    method: "delete",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};