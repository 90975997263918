import {
  Box,
  Button,
  ButtonGroup,
  Grid2,
  IconButton,
  Paper,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "../../components/tables/DataTable";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import {
  FilterTitleBox,
  FiltersBox,
} from "../../components/layouts/OneViewBox";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
import AsyncSearchBar from "../../components/inputs/AsyncSearchBar";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";

import { USER_ROLES } from "../../utils/constants";
import DashboardProductCounts from "../dashboard/DashboardProductCounts";
import ButtonComponentsUi from "../../components/button/ButtonComponentsUi";

const StockOfProductListUi = ({
  columns,
  list,
  filters,
  setFilters,
  loading,
  onExportClick,
  onChannelSaleClick,
  title,
  categoryType,
  setCategoryType,
  setSelectedButtonId,
  selectedButtonId,
  count,
  loadingCount
}) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const id = "product";
  const { settings } = useSelector((state) => state);

  return (
    <>
      <Box>
        <Paper
          sx={{
            width: "100%",
            padding: 6,
          }}
        >
          <Box
            mb={4}
            display="flex"
            flexDirection="column"
            gap={isSmallScreen ? 2 : 4}
          >
            <FilterTitleBox>
              <Typography
                variant={isSmallScreen ? "h2" : "h3"}
                textAlign={isSmallScreen ? "left" : "left"}
                marginBottom={isSmallScreen ? "5px" : "0"}
                color={"#000"}
              >
                {title}
              </Typography>

            </FilterTitleBox>

            {loadingCount ? (
              <Box
                mt={4}
                mb={4}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  height: "15vh",
                }}
              >
                {[0, 1, 2, 3].map((row) => (
                  <Skeleton
                    key={row}
                    variant="rectangular"
                    width={"24%"}
                    height={120}
                    sx={{
                      borderRadius: "8px",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                  />
                ))}
              </Box>
            )
              :
              (<Grid2 container mt={4} p={2} size={12} gap={4} display={"flex"} justifyContent={"space-between"} width={"100%"}>
                {count?.length > 0 && count?.map((item) => (<>
                  <Grid2 display={"flex"} flexDirection={"row"} maxWidth={"25vw"} minWidth={"15vW"} flexWrap={"wrap"} size={count?.length / 3}>
                    <ButtonComponentsUi onSubmit={() => {
                      setSelectedButtonId(item?._id);
                    }}
                      count={item?.count ?? 0}
                      colorType={selectedButtonId === item._id}
                      Title={item?.product_name}
                    />
                  </Grid2>
                </>))}
              </Grid2>)
            }
            <Box
              display="flex"
              justifyContent={isSmallScreen ? "space-between" : "space-between"}
              alignItems="center"
              mt={1}
            >
              <Box display={"flex"}>
                <Button
                  onClick={onExportClick}
                  sx={{
                    width: isSmallScreen ? "40%" : "16vw",
                    height: "6vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  variant="contained"
                >
                  <Typography variant="h4" sx={{ display: "flex" }}>
                    Import
                  </Typography>
                </Button>
              </Box>

              <Box sx={{ display: "flex", direction: "row", gap: "23px" }}>
                <Button
                  onClick={onChannelSaleClick}
                  sx={{
                    width: isSmallScreen ? "40%" : "16vw",
                    height: "6vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  variant="contained"
                >
                  <AddIcon /> &nbsp;
                  <Typography variant="h4" sx={{ display: "flex" }}>
                    Sale
                  </Typography>
                </Button>
                <PaddingBoxInDesktop
                  sx={{
                    display: "flex",
                    width: isSmallScreen ? "50vw" : "20vw",
                  }}
                >
                  <AsyncSearchBar
                    fullWidth
                    title="Search By Product Name"
                    size="small"
                    placeholder={"Search By Product Name"}
                    defaultValue={filters?.search}
                    onChange={(changedVal) =>
                      setFilters({
                        ...filters,
                        pageNo: 1,
                        pageSize: 10,
                        search: changedVal,
                      })
                    }
                  />
                </PaddingBoxInDesktop>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              minHeight: "40vh",
            }}
          >
            <DataTable
              columns={columns}
              rows={list?.result ? list?.result : []}
              count={list?.total ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default StockOfProductListUi;
