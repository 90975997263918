import { CircularProgress,  ListItem, } from "@mui/material"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import CustomInput from "../../components/inputs/CustomInputs"
import { memo} from "react"

import { StyledSearchBar } from "../../components/inputs/SearchBar"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop"
import { fetchTypeApi } from "../../apis/productType.api"
import { fetchColorApi } from "../../apis/productColor.api"


const CreateProductUi = ({ title, isUpdate, fields, setFields, loading, onSubmit, isModal, index, handleAreaModalClose }) => {
    

return <>

        <CustomDialog
            id={`${isUpdate ? "productupdate" : "product"}`}
            loading={loading}
            err={fields?.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >
            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    {<CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields?.product_name}
                        onChange={(e) => setFields({ ...fields, err: '', product_name: e.target.value })}
                        type="text"
                        label={"Product Name*"}
                    />}
                    {<CustomInput
                        disabled={loading}
                        value={fields?.product_code}
                        onChange={(e) => setFields({ ...fields, err: '', product_code: e.target.value })}
                        type="text"
                        label={"Product Code*"}
                    />}

                    {<CustomInput
                        disabled={loading}
                        value={fields.mrp}
                        onChange={(e) => setFields({ ...fields, err: '', mrp: e.target.value })}
                        type="text"
                        label={"Product Price*"}
                    />}
                     {<PaddingBoxInDesktop mt={3} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                        <AsyncDropDown
                            defaultVal={
                                fields.product_type ? {

                                    _id: fields.product_type._id,
                                    name: fields.product_type.name,
                                }
                                    : null
                            }
                            lazyFun={async (para) => await fetchTypeApi({ ...para, allStatus: true })}
                            OptionComponent={({ option, ...rest }) => {
                                return <ListItem {...rest}>{option.name}</ListItem >
                            }}
                            value={fields?.product_type}
                            onChange={async (changedVal) => {
                                setFields({ ...fields, product_type: changedVal ? changedVal._id : null });
                            }}

                            titleKey={'name'}
                            valueKey={"_id"}
                            InputComponent={(params) => <StyledSearchBar placeholder={"Select Product Type*"} {...params} margin="none" />}
                        />
                    </PaddingBoxInDesktop>}

                    {<PaddingBoxInDesktop mt={3} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                        <AsyncDropDown
                            defaultVal={
                                fields.product_color ? {

                                    _id: fields.product_color._id,
                                    name: fields.product_color.name,
                                }
                                    : null
                            }
                            lazyFun={async (para) => await fetchColorApi({ ...para, allStatus: true })}
                            OptionComponent={({ option, ...rest }) => {
                                return <ListItem {...rest}>{option.name}</ListItem >
                            }}
                            value={fields?.product_color}
                            onChange={async (changedVal) => {
                                setFields({ ...fields, product_color: changedVal ? changedVal._id : null });
                            }}

                            titleKey={'name'}
                            valueKey={"_id"}
                            InputComponent={(params) => <StyledSearchBar placeholder={"Select Product Color*"} {...params} margin="none" />}
                        />
                    </PaddingBoxInDesktop>}


                </>
            }
        </CustomDialog>

    </>
}
export default memo(CreateProductUi)