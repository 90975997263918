import React from 'react'
import CustomDialog from '../../components/layouts/common/CustomDialog'
import CustomInput from '../../components/inputs/CustomInputs'
import { Autocomplete, Box, Chip, ListItem, TextField } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'

const AddPaymentUi = ({ loading, title, fields, setFields, createFunction, listProblem }) => {

    return (
        <CustomDialog
            id={"AddPayment"}
            title={title}
            loading={loading}
            closeText={"Close"}
            confirmText={"Add"}
            err={fields?.err}
            onSubmit={(e) => createFunction(e)}
        >

            <Box>

                <Box mt={1}>
                    <DesktopDatePicker
                        sx={{ width: "18vw", height: "100%" }}
                        disableFuture
                        inputFormat="DD/MM/YYYY"
                        autoFocus={true}
                        renderInput={(props) => (
                            <CustomInput {...props} />
                        )}
                        disabled={loading}
                        value={fields.date ? moment(fields.date) : null}
                        onChange={(val) => {
                            if (val && val.isValid()) {
                                const isoString = val.toISOString();
                                setFields({
                                    ...fields,
                                    err: "",
                                    date: isoString,
                                    // age: calculateAge(isoString),
                                });
                                // setIsAgeModified(false);
                            } else {
                                setFields({
                                    ...fields,
                                    err: "",
                                    date: "",
                                    age: 0,
                                });
                            }
                        }}
                        label={"Payment Date*"}
                    />
                </Box>

                {/* <Box mt={1}>
                    <CustomInput
                        disabled={loading}
                        value={fields.invoice_no}
                        onChange={(e) =>
                            setFields({
                                ...fields,
                                err: "",
                                invoice_no: e.target.value,
                            })
                        }
                        label={"Invoice No*"}
                        type="text"
                    // onWheel={(e) => e.target.blur()}
                    // sx={{
                    //     "& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button": {
                    //         WebkitAppearance: "none",
                    //         margin: 0,
                    //     },
                    //     "& input": {
                    //         MozAppearance: "textfield",
                    //     },
                    // }}
                    />
                </Box> */}

                <Box mt={1}>
                    <CustomInput
                        disabled={loading}
                        value={fields.total_amount}
                        onChange={(e) =>
                            setFields({
                                ...fields,
                                err: "",
                                total_amount: e.target.value,
                            })
                        }
                        type="text"
                        label={"Total Amount*"}
                    />
                </Box>
            </Box>
        </CustomDialog>
    )
}

export default AddPaymentUi