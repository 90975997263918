export const USER_ROLES = {
  WEHEAR_ADMIN: 1,
  WEHEAR_MANAGER: 2,
  LENSKART_ADMIN: 3,
  LENSKART_STORE: 4,
  AUDIOLOGIST: 5
};
export const actions = {
  SIGN_IN: "SIGN_IN",
  SET_USER: "SET_USER",
  SIGN_OUT: "SIGN_OUT",
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
  UPDATE_USER_DATA: "UPDATE_USER_DATA",
  SET_USER_LAST_READ_NOTIFICATION: "SET_USER_LAST_READ_NOTIFICATION",
  CALL_SNACKBAR_DATA: "CALL_SNACKBAR_DATA",
  SET_NOTIFICATION_DATA: "SET_NOTIFICATION_DATA",
  NOTIFICATION_READ: "NOTIFICATION_READ",

  SET_INVENTORY_COUNT_DATA: "SET_INVENTORY_COUNT_DATA",
  SET_INVENTORY_COUNT_FILTERS: "SET_INVENTORY_COUNT_FILTERS",
  START_INVENTORY_COUNT_LOADING: "START_INVENTORY_COUNT_LOADING",
  SET_INVENTORY_LIST: "SET_INVENTORY_LIST",
  UPDATE_INVENTORY_LIST: "UPDATE_INVENTORY_LIST",

  FETCH_DATA_PRODUCT_LODING: "FETCH_DATA_PRODUCT_LODING",
  FETCH_DATA_PRODUCT_DATA: "FETCH_DATA_PRODUCT_DATA",
  FETCH_DATA_PRODUCT_COUNT_LODING: "FETCH_DATA_PRODUCT_COUNT_LODING",
  FETCH_DATA_PRODUCT_COUNT: "FETCH_DATA_PRODUCT_COUNT",
  FETCH_DATA_PRODUCT_DELETE: "FETCH_DATA_PRODUCT_DELETE",

  FETCH_DATA_UPDATE_STOCK_PRODUCT_LODING:
    "FETCH_DATA_UPDATE_STOCK_PRODUCT_LODING",
  FETCH_DATA_UPDATE_STOCK_PRODUCT_DATA: "FETCH_DATA_UPDATE_STOCK_PRODUCT_DATA",
  FETCH_DATA_UPDATE_STOCK_PRODUCT_DELETE:
    "FETCH_DATA_UPDATE_STOCK_PRODUCT_DELETE",

  FETCH_DATA_PRODUCT_COLOR_LODING: "FETCH_DATA_PRODUCT_COLOR_LODING",
  FETCH_DATA_PRODUCT_COLOR_DATA: "FETCH_DATA_PRODUCT_COLOR_DATA",
  FETCH_DATA_PRODUCT_COLOR_DELETE: "FETCH_DATA_PRODUCT_COLOR_DELETE",

  FETCH_DATA_PRODUCT_TYPE_LODING: "FETCH_DATA_PRODUCT_TYPE_LODING",
  FETCH_DATA_PRODUCT_TYPE_DATA: "FETCH_DATA_PRODUCT_TYPE_DATA",
  FETCH_DATA_PRODUCT_TYPE_DELETE: "FETCH_DATA_PRODUCT_TYPE_DELETE",

  SET_DEVICES_LIST: "SET_DEVICES_LIST",
  UPDATE_DEVICES_LIST: "UPDATE_DEVICES_LIST",
  REMOVE_DEVICE_LIST: "REMOVE_DEVICE_LIST",
  SET_ERRORS: "SET_ERRORS",
};

export const NOTIFICATION_TYPE = {
  general: "general",
  lead: "lead",
};

export const YES_NO = {
  YES: true,
  NO: false,
};

export const SNACK_BAR_VARIETNS = {
  error: "error",
  suceess: "success",
  info: "info",
  warning: "warning",
};

export const DAY_WEEK_MONTH = {
  DAY: "day",
  WEEK: "week",
};

// export const INVENTORY_TYPE = {
//   SALEABLE: 1,
//   TRIALS: 2,
//   SOLD: 3,
//   TRIAL: 4,
//   ALL: 5,
// };

export const SALEABLE_TYPE = {
  SOLD: 3,
  TRIAL: 2,
  ALL: 4,
};

export const GENDER = {
  MALE: 1,
  FEMALE: 2,
  OTHER: 3,
};

export const CUSTOMER_TYPE = {
  TESTED: 1,
  TRIALS: 2,
  PURCHASED: 3,
  RETURN: 4,
  ALL: 5,
};

export const INVOICE_TYPE = {
  TRIALS: 2,
  PURCHESED: 3,
  RETURN: 4,
}
export const STOCKLOG_STATUS = {
  SELL: 1,
  RETURN: 2,
  REPLACEMENT: 3,
};

export const INVENTORY_TYPE = {
  SALEABLE: 3,
  TRIAL: 2,
  ALL: 1,
};

export const DATE_TIME_FILTERS = {
  this_week: "This Week",
  this_month: "This Month",
  this_year: "This Year",
  last_month: "Last Month",
  last_year: "Last Year",
  last_week: "Last Week",
  today: "Today",
  yesterday: "Yesterday",
};

export const PAYMENT_STATUS = {
  PENDING: 0,
  COMPLETED: 1,
};

export const STATUS_COLOR_TYPE = (currentstatus) => {
  switch (currentstatus) {
    case true:
      return "#ECFDF5";
    default:
      return "white";
  }
};

export const BUTTON_STATUS_COLOR_TYPE = (currentstatus) => {
  switch (currentstatus) {
    case true:
      return "contained";
    default:
      return "outlined";
  }
};

export const LEVEL_OF_HEARING_LOSS = {
  MILD: 1,
  MODERATE: 2,
  SEVERE: 3,
  PROFOUND: 4,
};

export const TYPE_OF_HEARING_LOSS = {
  CONDUCTIVE: 1,
  SN: 2,
  MIXED: 3,
};