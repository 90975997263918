import { Box, CircularProgress, Typography, Checkbox } from "@mui/material";
import { memo } from "react";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import MODULES from "../../utils/module.constant";
import { titleCase } from "../../utils/main";

const ModulePermissionui = ({
  title,
  fields,
  setFields,
  loading,
  onSubmit,
  modalKey,
}) => {
  const addModule = (val) => {
    const existingModules = [...(fields.modules ?? [])];
    existingModules.push(val);
    setFields({ ...fields, modules: existingModules });
  };
  const removeModule = (val) => {
    const existingModules = [...(fields.modules ?? [])];
    const index = existingModules.indexOf(val);
    if (index > -1) {
      existingModules.splice(index, 1);
    }
    setFields({ ...fields, modules: existingModules });
  };

  return (
    <>
      <CustomDialog
        id={modalKey}
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`Change  ${title} permissions`}
        closeText="Close"
        confirmText={`Change permissions`}
      >
        {loading && (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        )}
        {
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                "*": { justifyContent: "flex-start" },
              }}
            >
              {Object.keys(MODULES).map((item) => (
                <Typography key={item} variant="h6">
                   {titleCase(item)}
                  <Checkbox
                    onClick={() => {
                      if (
                        fields.modules &&
                        fields.modules?.includes(MODULES[item])
                      ) {
                        removeModule(MODULES[item]);
                      } else addModule(MODULES[item]);
                    }}
                    checked={
                      fields.modules && fields.modules?.includes(MODULES[item])
                    }
                  />
                </Typography>
              ))}
            </Box>
          </>
        }
      </CustomDialog>
    </>
  );
};
export default memo(ModulePermissionui);
