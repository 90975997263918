
import { Delete, Publish, Remove } from "@mui/icons-material"
import { Box, Button, Checkbox, Chip, CircularProgress, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import { memo, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { fetchProducts, fetchSoldTypes } from "../../../apis/inventory.api"
// import { center } from "../../../assets/css/theme/common";

// import SubmitButton from "../../../components/button/SubmitButton"
// import AsyncDropDown from "../../../components/inputs/AsyncDropDown"
// import CustomInput from "../../../components/inputs/CustomInput"
// import { removeItemFromDeviceList } from "../../../store/actions/importDeviceSheetAction"

// import { updateInventoryListAction } from "../../../store/actions/invenotryListAction"
import { closeModal } from "../../store/actions/modalAction"
import SubmitButton from "../../components/button/SubmitButton"
import CustomInput from "../../components/inputs/CustomInputs"
import { removeItemFromDeviceList } from "../../store/actions/importDeviceSheetAction"
// import { permissions } from "../../../utils/constants"

const ImportCsvCreateUi = ({ fields, setFields, onSubmit, onFileSelect, dispatchSheet, data, loading,hasError }) => {
    console.log("data",data)
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(closeModal())
    }
    return <>

        <DialogTitle variant="h3">
            Import Stock
            <Typography variant="h6" color="red">{fields.err} </Typography>
        </DialogTitle>

        <DialogContent sx={{ minHeight: '100px', display: "flex", position: "relative" }}>

            <Box sx={{ minHeight: '100px', width: "100%", display: "flex" }}>
                {!loading && data && data.length == 0 &&
                    <Button variant="outlined" component="label" fullWidth={true}>
                        <Publish fontSize="large" />

                        <Typography >Upload CSV</Typography>
                        <input hidden accept=".csv" onChange={onFileSelect} type="file" />
                    </Button>
                }
                {
                    loading && <Box sx={{  width: "100%" }}><CircularProgress /></Box>
                }


                {!loading && data && data.length > 0 && <>


                    <Box sx={{ border: 1, p: 1, borderRadius: 1, flexDirection: "column", overflowY: "scroll", flex: 3, display: 'flex', maxHeight: "100%" }}>
                        <Typography variant="h5" mb={2}>data</Typography>
                        <TableContainer >


                            <Table stickyHeader aria-label="collapsible table">

                                <TableHead sx={{ zIndex: 1 }}>
                                    <TableRow sx={{ backgroundColor: 'red' }}>
                                        {/* <TableCell></TableCell> */}
                                        <TableCell>Box Id</TableCell>
                                        <TableCell>Product </TableCell>
                                        <TableCell>Product Color </TableCell>
                                        {/* <TableCell>Actions</TableCell> */}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                {/* console.log("ROWWWW",row)) */}
                                    {
                                        data?.map((row) =>
                                        <Row err={row?.hasError} key={row?.data?.id} id={row?.data?.id} dispatchSheet={dispatchSheet} box_id={row?.data?.box_id} color_id={row?.data?.color_id} product_id={row?.data?.product_id} />)
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>


                    </Box>

                    {/* <Box sx={{ border: 1, p: 1, borderRadius: 1, ml: 1, flexDirection: "column", overflowY: "scroll", flex: 1, display: 'flex', maxHeight: "100%" }}> */}
                        {/* <Typography variant="h5" mb={2}>Details</Typography> */}



                        {/* <Box sx={{}}><CustomInput type="text" placeholder="Box Id" onChange={(e) => setFields({ ...fields, box_id: e.target.value })} /></Box>
                        <Box sx={{}}><CustomInput type="text" placeholder="Product" onChange={(e) => setFields({ ...fields, product_id: e.target.value })} /></Box>
                        <Box sx={{}}><CustomInput type="text" placeholder="Product color" onChange={(e) => setFields({ ...fields, color_id: e.target.value })} /></Box> */}

                        {/* {
                            <Box sx={{}}>
                                <AsyncDropDown
                                    OptionComponent={({ option, ...rest }) => {
                                        return <MenuItem {...rest}>{option.name}</MenuItem>
                                    }}


                                    label="Products"
                                    onChange={async (changedVal) => { setFields({ ...fields, err: '', product: changedVal?._id }) }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    lazyFun={fetchProducts} />
                            </Box>} */}

                    {/* </Box> */}
                </>}

            </Box>
        </DialogContent>
        <DialogActions>
            <SubmitButton onClick={handleClose}  title={'close'}></SubmitButton>
            <SubmitButton disabled={data?.length == 0} onClick={onSubmit ? onSubmit : ""} title="Import" ></SubmitButton>
        </DialogActions>


    </>
}


export default ImportCsvCreateUi

const Row = memo(({ dispatchSheet, err, id, box_id, product_id, color_id }) => {
    console.log('soham',box_id,product_id,color_id)
    // const dispatch = useDispatch()

    // const onRemoveButtonClick = () => {
    //     dispatch(removeItemFromDeviceList(box_id))
    // }
    return <Tooltip title={err}>
        <TableRow sx={{ background: err != "" ? "pink" : "white" }}>
            <TableCell>{box_id?.value}</TableCell>
            <TableCell>{product_id?.value}</TableCell>
            <TableCell>{color_id?.value}</TableCell>

            {/* <TableCell>{
                convertedBoxes?.map((val) => <Chip key={val} label={val} />)
            }</TableCell> */}
            {/* <TableCell><IconButton><Remove onClick={onRemoveButtonClick}/></IconButton></TableCell> */}
        </TableRow>
    </Tooltip>
})

