import {
  Box,
  Paper,
  Skeleton,
  styled,
  Typography,
  useMediaQuery,
  ButtonGroup,
  Grid2,
} from "@mui/material";
import React, { useState } from "react";
import ButtonComponentsUi from "../../../components/button/ButtonComponentsUi";
import { INVENTORY_TYPE } from "../../../utils/constants";
import { findObjectKeyByValue, titleCase } from "../../../utils/main";
import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxDesktop";
import TimeRangeSelector from "../../../components/layouts/common/TimeRangeSelector";
import AsyncSearchBar from "../../../components/inputs/AsyncSearchBar";
import DataTable from "../../../components/tables/DataTable";
import { useSelector } from "react-redux";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
  padding: "8px",
  marginTop: "12px",
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const StoreStockUi = ({
  setButtonStatus,
  selectedButtonId,
  setSelectedButtonId,
  buttonStatus,
  columns,
  list,
  filters,
  setFilters,
  itemType,
  loading,
  count,
  countLoading,
}) => {
  const user = useSelector((state) => state.user);
  const { settings } = useSelector((state) => state);
  // const [selectedButtonId, setSelectedButtonId] = useState(null);
  // const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"))
  // const issmSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"))

  return (
    <Box sx={{ scrollbarWidth: "0px" }}>
      <Paper sx={{ padding: 4, width: "100%" }}>
        {/* <Box mt={4} gap={4} p={2} display={"flex"} justifyContent={"space-between"} flexDirection={"row"}>
                <Box width={"25vw"}>
                    <ButtonComponentsUi count={(count[0]?.count ?? 0) + (count[1]?.count ?? 0)} Title={"All"} />
                </Box>
                <Box width={"25vw"}>
                    <ButtonComponentsUi count={count[0]?.count ?? 0} onSubmit={() => setButtonStatus(INVENTORY_TYPE.SALEABLE)} colorType={buttonStatus === INVENTORY_TYPE.SALEABLE} Title={titleCase(findObjectKeyByValue(INVENTORY_TYPE.SALEABLE, INVENTORY_TYPE))} />
                </Box>
                <Box width={"25vw"}>
                    <ButtonComponentsUi count={count[1]?.count ?? 0} onSubmit={() => setButtonStatus(INVENTORY_TYPE.TRIAL)} colorType={buttonStatus === INVENTORY_TYPE.TRIAL} Title={titleCase(findObjectKeyByValue(INVENTORY_TYPE.TRIAL, INVENTORY_TYPE))} />
                </Box>
                
                </Box> */}

        <Box>
          <Typography variant="h3">Stock</Typography>
        </Box>

        <FilterTitleBox>
          <Box>
            <PaddingBoxInDesktop
              sx={{
                display: "flex",
                width: "30vw",
                flexDirection: "flex-start",
              }}
            >
              <TimeRangeSelector
                onChange={(val) => setFilters({ ...filters, ...val })}
              />
            </PaddingBoxInDesktop>
          </Box>

          <Box width={"48%"}>
            <ButtonGroup sx={{ flexDirection: "row", width: "100%" }}>
              <ButtonComponentsUi
                count={
                  (count[0]?.count ?? 0) +
                  (count[1]?.count ?? 0) +
                  (count[2]?.count ?? 0)
                }
                onSubmit={() => setButtonStatus(INVENTORY_TYPE.ALL)}
                ButtonGroup
                STATUSWiseData={buttonStatus == INVENTORY_TYPE.ALL}
                Title={titleCase(
                  findObjectKeyByValue(INVENTORY_TYPE.ALL, INVENTORY_TYPE)
                )}
              />
              <ButtonComponentsUi
                count={count[0]?.count ?? 0}
                onSubmit={() => setButtonStatus(INVENTORY_TYPE.SALEABLE)}
                ButtonGroup
                STATUSWiseData={buttonStatus == INVENTORY_TYPE.SALEABLE}
                Title={titleCase(
                  findObjectKeyByValue(INVENTORY_TYPE.SALEABLE, INVENTORY_TYPE)
                )}
              />
              <ButtonComponentsUi
                count={count[1]?.count ?? 0}
                onSubmit={() => setButtonStatus(INVENTORY_TYPE.TRIAL)}
                ButtonGroup
                STATUSWiseData={buttonStatus == INVENTORY_TYPE.TRIAL}
                Title={titleCase(
                  findObjectKeyByValue(INVENTORY_TYPE.TRIAL, INVENTORY_TYPE)
                )}
              />
            </ButtonGroup>
          </Box>
        </FilterTitleBox>

        {countLoading ? (
          <Box
            mt={4}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              height: "15vh",
            }}
          >
            {[0, 1, 2, 3].map((row) => (
              <Skeleton
                key={row}
                variant="rectangular"
                width={"24%"}
                height={120}
                sx={{
                  borderRadius: "8px",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
              />
            ))}
          </Box>
        ) : (

          <Grid2
            container
            mt={4}
            p={2}
            size={12}
            justifyContent={"space-between"}
          >
            {" "}
            {count?.map((listData) => (
              <>
                <Grid2
                  display={"flex"}
                  mb={4}
                  width={"18vw"}
                  flexDirection={"row"}
                  justifyContent={"flex-start"}
                  flexWrap={"wrap"}
                  size={listData.length / 3}
                >
                  <ButtonComponentsUi
                    count={listData?.count ?? 0}
                    onSubmit={() => {
                      // setButtonStatus(SALEABLE_TYPE.ALL);
                      setSelectedButtonId(listData._id);
                    }}
                    colorType={selectedButtonId === listData._id}
                    Title={listData?.product_name}
                  />
                </Grid2>
              </>
            ))}
          </Grid2>
        )}



        <Box
          p={2}
          mt={4}
          width={"100%"}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          {/* <Box>

                    <PaddingBoxInDesktop
                        sx={{
                            display: "flex",
                            width: "20vw",
                            flexDirection: "flex-start",
                        }}
                    >

                    </PaddingBoxInDesktop>
                </Box> */}
          <Box>
            <PaddingBoxInDesktop
              sx={{
                display: "flex",
                width: "20vw",
                flexDirection: "flex-start",
              }}
            >
              <AsyncSearchBar
                fullWidth
                title="Search By Name"
                size="small"
                placeholder={"Search By Name"}
                defaultValue={filters?.search}
                onChange={(changedVal) =>
                  setFilters({
                    ...filters,
                    pageNo: 1,
                    pageSize: 10,
                    search: changedVal,
                  })
                }
              />
            </PaddingBoxInDesktop>
          </Box>
        </Box>

        <Box p={2} sx={{ minHeight: "40vh" }}>
          <DataTable
            columns={columns}
            rows={list?.result ? list.result : []}
            count={list?.result?.length ?? 0}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default StoreStockUi;
