
const mode = "production";
let domain = "";

switch (mode) {
  case "ip":
    domain = "http://192.168.137.186:8001/";
    break;
  case "local":
    domain = "http://localhost:8001/";
    break;

  case "production":
    domain = "https://lk-ims-backend-production.up.railway.app/";
    break;
  default:
    domain = "/";
}

const endpoints = {
  root: domain,
  fileImage: `${domain}api/file/image`,
  fileFile: `${domain}api/file/file`,
  fileBase: `${domain}api/file`,
  notificationFetch: `${domain}api/notification`,
  signIn: `${domain}api/auth/login`,
  resetToken: `${domain}api/auth/reset-token`,
  resetPassword: `${domain}api/auth/reset-password`,
  userCount: `${domain}api/user/count`,
  userAndSystemFetchByToken: `${domain}api/user/fetch-by-token`,
  userBase: `${domain}api/user/`,
  userById: `${domain}api/user/fetch-by-id`,

  userDeleteUndo: `${domain}api/user/undo-delete`,
  userPassword: `${domain}api/user/reset-password`,
  secretsBase: `${domain}api/secrets/`,
  secretsById: `${domain}api/secrets/by-id`,

  ProductCreate: `${domain}api/product/`,
  ProductFetch: `${domain}api/product/fetch`,
  prodctListFetch: `${domain}api/product/all`,
  ProductDelete: `${domain}api/product/delete`,
  ProductUpdate: `${domain}api/product/update`,
  ProductFetchById: `${domain}api/product/fetch-by-id`,
  FetchProductStockById: `${domain}api/product/stock-byid`,


  createProductStock: `${domain}api/inventory/`,
  fetchProductStock: `${domain}api/inventory/`,
  fetchFindByBoxId: `${domain}api/inventory/fetch-boxId`,
  transferProductStock: `${domain}api/inventory/transfer-devices`,
  fetchProductStockCounts: `${domain}api/inventory/product-wise-count`,

  colorCreate: `${domain}api/product-color/`,
  colorFetch: `${domain}api/product-color/fetch`,
  colorFetchById: `${domain}api/product-color/by-id`,
  colorDelete: `${domain}api/product-color/delete`,
  colorUpdate: `${domain}api/product-color/update`,

  productTypeCreate: `${domain}api/product-type/`,
  productTypeFetch: `${domain}api/product-type/fetch`,
  productTypeFetchById: `${domain}api/product-type/by-id`,
  productTypeDelete: `${domain}api/product-type/delete`,
  productTypeUpdate: `${domain}api/product-type/`,

  updateStock: `${domain}api/stock/update-stock`,
  fetchStock: `${domain}api/stock/fetch-logs`,
  fetchStockById: `${domain}api/stock/`,
  fetchStockByStoreId: `${domain}api/stock/fetch-store-stock`,
  fetchwareHouseStock: `${domain}api/stock/warehouse-count`,
  fetchwareHouseStockData: `${domain}api/stock/warehouse-data`,

  createCustomer: `${domain}api/customer/create`,
  updateCustomer: `${domain}api/customer/update`,
  fetchCustomer: `${domain}api/customer/by-storeid`,
  fetchCustomerById: `${domain}api/customer/by-id`,
  deleteCustomer: `${domain}api/customer/delete`,
  stockLogsApi: `${domain}api/stock/fetch-logs`,
  stockCountApi: `${domain}api/stock/store-stock-count`,

  salesCountApi: `${domain}api/stock/store-sales-count`,
  createCustomer: `${domain}api/customer/create`,
  updateCustomer: `${domain}api/customer/update`,
  fetchCustomer: `${domain}api/customer/by-storeid`,
  fetchCustomerById: `${domain}api/customer/by-id`,
  customerCountApi: `${domain}api/customer/fetch-count`,
  deleteCustomer: `${domain}api/customer/delete`,
  stockLogsApi: `${domain}api/stock/fetch-logs`,
  stockCountApi: `${domain}api/stock/store-stock-count`,

  fetchSalesPerson: `${domain}api/sales-person/fetch`,
  fetchSalesPersonById: `${domain}api/sales-person/by-id`,
  createSalesPerson: `${domain}api/sales-person/`,
  updateSalesPerson: `${domain}api/sales-person/update`,
  deleteSalesPerson: `${domain}api/sales-person/delete`,

  salesCountApi: `${domain}api/stock/store-sales-count`,

  FetchCustomerOrSales: `${domain}api/stock/sales-data`,

  CreatePaymentStatus: `${domain}api/stock/create-stock`,

  ledgerPdf: `${domain}api/ledger/pdf`
};

export default endpoints;
