import { Autocomplete, Box, CircularProgress, FormControlLabel, FormLabel, Grid2, ListItem, Radio, RadioGroup } from "@mui/material"
import { memo } from "react"
import { useSelector } from 'react-redux'
import { GENDER, USER_ROLES } from "../../utils/constants"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import CustomInput from "../../components/inputs/CustomInputs"
import { findNameByRole, titleCase } from "../../utils/main"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getUserApi } from "../../apis/user.api"
import { StyledSearchBar } from "../../components/inputs/SearchBar"



const SalesPersonCreateUi = ({ title, isUpdate, fields, setFields, loading, onSubmit }) => {
  const { user } = useSelector(state => state)
  return <>

    <CustomDialog
      loading={loading}
      err={fields.err}
      onSubmit={onSubmit}
      title={`${isUpdate ? "Update" : "Create"} ${title}`}
      closeText="Close"
      confirmText={`${isUpdate ? "Update" : "Create"}`}
    >

      {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
        <>
          <CustomInput
            autoFocus={true}
            disabled={loading}
            value={fields.name}
            onChange={(e) => setFields({ ...fields, err: '', name: e.target.value })}
            type="text"
            label={"Name*"}

          />



          {/* {user.data.role !== USER_ROLES.SALES_PERSON && ( */}
          <Box width={"100%"} mt={1}>
            <AsyncDropDown
              defaultVal={fields?.store_id}
              lazyFun={async (para) => await getUserApi({ ...para })}
              OptionComponent={({ option, ...rest }) => {
                return option.role === USER_ROLES.LENSKART_STORE && <ListItem {...rest}>{option.name}</ListItem>;
              }}
              value={fields?.store_id ?? []}
              onChange={(changedVal) => {
                setFields((prevFields) => ({
                  ...prevFields,
                  store_id: changedVal?._id ? changedVal?._id : null,
                  err: "",
                }));
              }}
              titleKey={"name"}
              valueKey={"_id"}
              InputComponent={(params) => (
                <StyledSearchBar
                  placeholder={"Select Store*"}
                  {...params}
                  margin="none"
                />
              )}
            />
          </Box>
          {/* )} */}


          <CustomInput
            disabled={loading}
            value={fields.sales_person_code}
            onChange={(e) => setFields({ ...fields, err: '', sales_person_code: e.target.value })}
            type="text"
            label={"Sales Person Code*"}

          />

          {
            <CustomInput
              disabled={loading}
              value={fields.phone}
              onChange={(e) => setFields({ ...fields, err: '', phone: e.target.value })}
              type="tel"
              label={"Phone No*"}

            />
          }




          <Box display="flex" gap={2} mt={0.5} mb={2}>
            {<CustomInput
              disabled={loading}
              value={fields.state}
              onChange={(e) => setFields({ ...fields, err: '', state: e.target.value })}
              type="text"
              label={"State*"}

            />}
            {<CustomInput
              disabled={loading}
              value={fields.city}
              onChange={(e) => setFields({ ...fields, err: '', city: e.target.value })}
              type="text"
              label={"City*"}

            />}
          </Box>

          <Grid2 size={5}
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              flexDirection: "row",
              alignItems: "center",
              border: "1px solid #101536",
              borderRadius: "5px",

            }}
          >

            <Box>
              <FormLabel
                sx={{ fontSize: "16px" }}
                disabled={loading}
                id="demo-row-radio-buttons-group-label"
              >
                Gender*
              </FormLabel>
            </Box>
            <Box
              sx={{
                width: "1px",
                height: "30px",
                backgroundColor: "navy",
              }}
            />

            <RadioGroup
              sx={{
              }}
              value={fields.gender}
              onChange={(e) =>
                setFields({
                  ...fields,
                  err: "",
                  gender: e.target.value,
                })
              }
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              {Object.keys(GENDER).map((item) => (
                <FormControlLabel
                  disabled={loading}
                  key={item}
                  value={GENDER[item]}
                  control={<Radio />}
                  label={titleCase(item)}
                />
              ))}
            </RadioGroup>
            {/* </FormControl> */}
          </Grid2>





        </>}
    </CustomDialog>
  </>
}
export default memo(SalesPersonCreateUi)
