import { Box, ButtonGroup, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import DataTable from "../../../components/tables/DataTable";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../../store/actions/commonAction";
import { fetchProductWiseInventory } from "../../../apis/inventory.api";
import { INVENTORY_TYPE, INVOICE_TYPE, SALEABLE_TYPE } from "../../../utils/constants";
import { findObjectKeyByValue, titleCase } from "../../../utils/main";
import ButtonComponentsUi from "../../../components/button/ButtonComponentsUi";

const WareHouseProductWiseDataController = ({ id, parent_id }) => {
    const dispatch = useDispatch();
    const [list, setList] = useState({});
    const [loading, setLoading] = useState(true);
    const [buttonStatus, setButtonStatus] = useState(INVENTORY_TYPE.ALL)

    const columns = useMemo(() => {
        return [
            {
                id: 1,
                minWidth: "50px",
                fieldName: "product_name",
                label: "Product",
                align: "left",
                sort: true,
                renderValue: (params) => params?.product_id?.product_name,
            },
            {
                id: 2,
                minWidth: "50px",
                fieldName: "box_id",
                label: "Box Id",
                align: "left",
                sort: true,
                renderValue: (params) => params?.box_id,
            },
            {
                id: 3,
                fieldName: "category",
                label: "Type",
                align: "left",
                sort: true,
                renderValue: (params) => findObjectKeyByValue(params?.category, INVENTORY_TYPE)
            },
        ];
    }, [dispatch]);
    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: "",
        searchable: ["name"],
        sort: "",
        sortDirection: -1,
    });

    const fetchList = () => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () =>
                    await fetchProductWiseInventory({ product_id: id, parent_id, ...filters, buttonStatus, notWehearDashboard: true }),
                (response) => {
                    setList(response);
                    console.log("this is open model response", response);
                    setLoading(false);
                },
                (err) => {
                    setLoading(false);
                }
            )
        );
    };

    useEffect(() => {
        if (id) {
            fetchList();
        }
    }, [buttonStatus]);

    return (
        <CustomDialog id={"productstock"} title={"Product Information"}>
            <Box width={"100%"} mb={2}>
                <ButtonGroup fullWidth sx={{ flexDirection: "row" }}>
                    <ButtonComponentsUi
                        width={500}
                        onSubmit={() => setButtonStatus(INVENTORY_TYPE.ALL)}
                        ButtonGroup
                        STATUSWiseData={buttonStatus === INVENTORY_TYPE.ALL}
                        Title={titleCase(
                            findObjectKeyByValue(INVENTORY_TYPE.ALL, INVENTORY_TYPE)
                        )}
                    />
                    <ButtonComponentsUi
                        onSubmit={() => setButtonStatus(INVENTORY_TYPE.SALEABLE)}
                        ButtonGroup
                        STATUSWiseData={buttonStatus === INVENTORY_TYPE.SALEABLE}
                        Title={titleCase(
                            findObjectKeyByValue(INVENTORY_TYPE.SALEABLE, INVENTORY_TYPE)
                        )}
                    />
                    <ButtonComponentsUi
                        onSubmit={() => setButtonStatus(INVENTORY_TYPE.TRIAL)}
                        ButtonGroup
                        STATUSWiseData={buttonStatus === INVENTORY_TYPE.TRIAL}
                        Title={titleCase(
                            findObjectKeyByValue(INVENTORY_TYPE.TRIAL, INVENTORY_TYPE)
                        )}
                    />
                </ButtonGroup>
            </Box>
            <DataTable
                columns={columns}
                rows={list?.result ? list?.result : []}
                count={list?.total ?? 0}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
            />
        </CustomDialog>
    );
};

export default WareHouseProductWiseDataController;