import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import LedgerListUi from "./LedgerListUi";
import { fetchLogsApi } from "../../apis/stock.api";
import moment from "moment";
import { findObjectKeyByValue } from "../../utils/main";
import { INVENTORY_TYPE, PAYMENT_STATUS, STOCKLOG_STATUS, USER_ROLES } from "../../utils/constants";
import fileDownload from "js-file-download"
import { DownloadLedgerApi } from "../../apis/ledger.api";
import { openModal } from "../../store/actions/modalAction";
import AddPaymentController from "./AddPaymentController";


const LedgerListController = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false)
  const title = "Ledger";
  const fetchApi = fetchLogsApi;
  const fetchDownloadApi = DownloadLedgerApi
  const user = useSelector(state => state.user)
  console.log("user", user?.data?.role)
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["product.product_name"],
    sort: "createdAt",
    sortDirection: -1,
    isLedger: true
  });

  const onAddPayment = () => {
    dispatch(
      openModal(
        <AddPaymentController
        // id={params._id}
        // callBack={(response, updatedData) => {
        //   setParams({ ...params, ...updatedData });
        // }}
        />,
        "sm", false, "AddPayment"
      )
    );
  };

  const [tab, setTab] = useState(0)

  const columns = useMemo(
    () => {

      let arr = []

      if (tab == 1) {
        arr.push(
          {
            id: 1,
            fieldName: "createdAt",
            label: "Date",
            align: "left",
            sort: true,
            renderValue: (params, setParams) => moment(params?.createdAt).format("DD/MM/YYYY")
          })
        arr.push(
          {
            id: 2,
            fieldName: "invoice_no",
            label: "Invoice No",
            align: "left",
            // renderValue:(params,setParams)=>params.product[0].product_name
          })
        arr.push(
          {
            id: 3,
            fieldName: "credit",
            label: "Credit",
            align: "left",
            renderValue: (params, setParams) => USER_ROLES.LENSKART_ADMIN == user.data.role ? (params?.debit == 0 ? "" : (params?.debit ?? "")) : params?.credit == 0 ? "" : (params?.credit ?? "")

          })
      }
      else if (tab == 2) {
        arr.push(
          {
            id: 1,
            fieldName: "createdAt",
            label: "Date",
            align: "left",
            sort: true,
            renderValue: (params, setParams) => moment(params?.createdAt).format("DD/MM/YYYY")
          })
        arr.push(
          {
            id: 2,
            fieldName: "invoice_no",
            label: "Invoice No",
            align: "left",
            // renderValue:(params,setParams)=>params.product[0].product_name
          })
        arr.push(
          {
            id: 3,
            fieldName: "debit",
            label: "Debit",
            align: "left",
            renderValue: (params, setParams) =>user.data.role == USER_ROLES.LENSKART_ADMIN ?  (params?.credit == 0 ? "" : (params?.credit ?? "")) :params?.debit == 0 ? "" :(params?.debit ?? "")

          })
      }
      else {
        arr.push(
          {
            id: 1,
            fieldName: "createdAt",
            label: "Date",
            align: "left",
            sort: true,
            renderValue: (params, setParams) => moment(params?.createdAt).format("DD/MM/YYYY")
          })
        arr.push(
          {
            id: 2,
            fieldName: "invoice_no",
            label: "Invoice No",
            align: "left",
            // renderValue:(params,setParams)=>params.product[0].product_name
          })
        arr.push(
          {
            id: 3,
            fieldName: "product",
            label: "Product Name",
            align: "left",
            sort: true,
            renderValue: (params, setParams) => params?.product?.product_name ? params?.product?.product_name : ""
          })
        arr.push(
          {
            id: 4,
            fieldName: "quantity",
            label: "Quantity",
            align: "left",
            renderValue: (params, setParams) => params?.quantity ? params?.quantity : ""
          })
        arr.push(
          {
            id: 5,
            fieldName: "amount_per_piece",
            label: "Rate Per Unit",
            align: "left",
            renderValue: (params, setParams) => params?.amount_per_piece ? params?.amount_per_piece : ""
            // renderValue:(params,setParams)=>params.product.mrp
          })
        arr.push(
          {
            id: 6,
            fieldName: "total_amount",
            label: "Product Price",
            align: "left",
            renderValue: (params, setParams) => params?.total_amount ? params?.total_amount : ""
          })
        arr.push(
          {
            id: 7,
            fieldName: "credit",
            label:user.data.role == USER_ROLES.WEHEAR_ADMIN ? "Credit" : "Debit",
            align: "left",
            renderValue: (params, setParams) => (params?.credit == 0 ? "" : params?.credit ?? "")

          })
          // user.data.role == USER_ROLES.WEHEAR_ADMIN ? "Debit" : "Credit",
        arr.push(
          {
            id: 8,
            fieldName: "debit",
            label: user.data.role == USER_ROLES.WEHEAR_ADMIN ? "Debit" : "Credit",
            align: "left",
            renderValue: (params, setParams) => params?.debit == 0 ? "" : params?.debit ?? ""

          })
      }

      return arr
    }
  );


  const [list, setList] = useState({})

  const downloadPdf = () => {
    setDownloadLoading(true)
    dispatch(callApiAction(
      async () => await fetchDownloadApi({ ...filters, isDebit: tab == 2, isCredit: tab == 1 }),
      (response) => {
        console.log("RESPONSE", response);
        fileDownload(response, "ledger.pdf")
        setDownloadLoading(false)
      },
      (err) => {
        setDownloadLoading(false)
      },
      true
    ))
  }

  const fetchList = () => {
    setLoading(true)
    dispatch(callApiAction(
      async () => await fetchApi({ ...filters }),
      (response) => {
        console.log("Responses", response)
        setList(response)
        setLoading(false)
      },
      (err) => {
        setLoading(false)
      }
    ))
  }

  useEffect(() => {
    fetchList()
  }, [filters])

  return (
    <>
      <LedgerListUi
        title={title}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        setList={setList}
        columns={columns}
        downloadPdf={downloadPdf}
        downloadLoading={downloadLoading}
        tab={tab}
        setTab={setTab}
        onAddPayment={onAddPayment}
      />
    </>
  );
};
export default LedgerListController;