import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useMemo } from "react";
import { Delete, Edit } from "@mui/icons-material";
import MessageDilog from "../../components/MessageDilog";
import {
  CUSTOMER_TYPE,
  GENDER,
  SNACK_BAR_VARIETNS,
} from "../../utils/constants";
import { callSnackBar } from "../../store/actions/snackbarAction";

import CustomerListUi from "./CustomerListUi";
import {
  deleteCustomerApi,
  FetchCustomerOrSalesApi,
  getCustomerApi,
  getCustomerCountApi,
} from "../../apis/customer.api";
import CustomerCreateController from "./CustomerCreateController";
import { findObjectKeyByValue } from "../../utils/helper";
import { useNavigate } from "react-router";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomerInformation from "./CustomerInformation";
import StepperController from "./StepperController";
import { fetchProductAction } from "../../store/actions/setting.Action";
import UpdateCustomerButton from "./UpdateCustomerButton";
import CustomerInformationController from "./CustomerInformationController";
import CustomerAdminUi from "./CustomerAdminUi";

const ActionComponent = ({ params, setParams, deleteApi, deleted }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state);

  const onEdit = () => {
    dispatch(
      openModal(
        <StepperController
          id={params._id}
        // callBack={(response, updatedData) => {
        //   setParams({ ...params, ...updatedData });
        // }}
        />,
        "md",
        false,
        "CustomerInformation"
      )
    );
  };

  const onVeiw = (id) => {
    dispatch(
      openModal(
        <CustomerInformationController id={params._id} />,
        "xl",
        false,
        "CustomerInformation"
      )
    );
  };

  const deleteFun = async (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteApi({ id: params._id }),
        (response) => {
          setParams({});
          setLoading(false);
          dispatch(
            callSnackBar(
              params.first_name + "Deleted Successfully",
              SNACK_BAR_VARIETNS.suceess
            )
          );
        },
        (err) => {
          setLoading(false);
        }
      )
    );
    dispatch(closeModal());
  };

  const onDelete = (e) => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={deleteFun}
          title="Alert!"
          message={`Are you sure you want to delete customer "${params.first_name || params.title
            }" ?`}
        />,
        "sm"
      )
    );
  };
  return (
    <Box sx={{ width: "100%", alignItems: "center", display: "flex" }}>
      <UpdateCustomerButton color="info" fontSize="inherit" params={params} />

      <Tooltip title="View">
        <IconButton size="inherit" onClick={onVeiw}>
          <VisibilityIcon color="info" fontSize="inherit" />
        </IconButton>
      </Tooltip>
      {/* <IconButton size="inherit" > */}
      {/* </IconButton> */}
      {/* <IconButton disabled={loading} size="inherit" onClick={onDelete}>
        <Delete color="error" fontSize="inherit" />
      </IconButton> */}
    </Box>
  );
};

const CustomerAdminController = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [buttonStatus, setButtonStatus] = useState(null);
  const [count, setCount] = useState([
    {
      count: "",
      customer_type: 1,
      _id: 1,
    },
    {
      count: "",
      customer_type: 2,
      _id: 2,
    },
    {
      count: "",
      customer_type: 3,
      _id: 3,
    },
  ]);
  const fetchcountApi = getCustomerCountApi;
  const title = "Customer";
  const fetchApi = FetchCustomerOrSalesApi;
  const deleteApi = deleteCustomerApi;
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    searchable: ["first_name", "email","last_name","phone"],
    search: "",
    role: "",
    sort: "createdAt",
    sortDirection: -1,
    deleted: null,
  });

  const columns = useMemo(() => {
    return [
      {
        id: 1,
        minWidth: "50px",
        fieldName: "first_name",
        label: "Full Name",
        align: "left",
        sort: true,
        renderValue: (params) => params.first_name + " " + params.last_name,
      },
      {
        id: 2,
        fieldName: "email",
        label: "Email",
        align: "left",
        sort: true,
      },

      {
        id: 3,
        fieldName: "phone",
        label: "Phone",
        align: "left",
        sort: true,
      },
      {
        id: 8,
        minWidth: "50px",
        fieldName: "gender",
        label: "Gender",
        align: "left",
        renderValue: (params) =>
          findObjectKeyByValue(parseInt(params.gender), GENDER),
      },
      {
        id: 5,
        fieldName: "",
        label: "Action",
        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            deleteApi={deleteApi}
            params={params}
            setParams={setParams}
          />
        ),
      },
    ];
  }, [dispatch]);

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters, customer_type: buttonStatus }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  const onCreateBtnClick = () => {
    navigate("/customer/create");
  };

  useEffect(() => {
    fetchList();
  }, [filters, buttonStatus]);

  const fetchCountList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchcountApi({}),
        (response) => {
          setCount(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  // const fetchCountList = () => {
  //   setLoading(true);
  //   dispatch(
  //     callApiAction(
  //       async () => await fetchcountApi({}),
  //       (response) => {

  //         setCount((prevCount) =>
  //           prevCount.map((item) => {
  //             const matchedResponse = response.find((res) => res.id === item._id);
  //             if (matchedResponse) {
  //               return {
  //                 ...item,
  //                 count: matchedResponse.count,
  //               };
  //             }
  //             return item;
  //           })
  //         );
  //         setLoading(false);
  //       },
  //       (err) => {
  //         setLoading(false);
  //       }
  //     )
  //   );
  // };

  useEffect(() => {
    fetchCountList();
    // dispatch(fetchProductAction(filters));
  }, [filters]);

  return (
    <>
      <CustomerAdminUi
        title={title}
        onCreateBtnClick={onCreateBtnClick}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
        setButtonStatus={setButtonStatus}
        buttonStatus={buttonStatus}
        setCount={setCount}
        count={count}
      />
    </>
  );
};
export default CustomerAdminController;
