import {
  Button,
  ButtonGroup,
  CircularProgress,
  Grid2,
  Paper,
  Skeleton,
  Typography,
  styled,
} from "@mui/material";
import { Box, useMediaQuery } from "@mui/system";
import { useSelector } from "react-redux";
import DataTable from "../../components/tables/DataTable";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import AsyncSearchBar from "../../components/inputs/AsyncSearchBar";
import AddIcon from "@mui/icons-material/Add";
import ButtonComponentsUi from '../../components/button/ButtonComponentsUi'
import { CUSTOMER_TYPE, USER_ROLES } from '../../utils/constants'
import { findObjectKeyByValue, titleCase, toTitleCase } from "../../utils/main";
import { useState } from "react";
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector";
import { CenteredBox } from "../../components/layouts/OneViewBox";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  // flexWrap: "wrap",
  flexDirection: "row",
  alignItems: "center",
  padding: 4,
  marginTop: "10px",  // [theme.breakpoints.down("md")]: {
  //   flexDirection: "row",
  // },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const CustomerListUi = ({
  title,
  filters,
  setFilters,
  list,
  roles,
  loading,
  onCreateBtnClick,
  columns,
  count,
  setButtonStatus,
  buttonStatus,
  countLoading,
  isAccordian
}) => {
  const user = useSelector((state) => state.user);
  const { settings } = useSelector((state) => state);
  const [selectedButtonId, setSelectedButtonId] = useState(null);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"))
  const issmSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"))
  return (
    <>
      <Box >
        <>
          <Paper elevation={0} sx={{ width: !isAccordian ? "100%" : "78vw", padding: 2 }}>
            <Box  >
              <FilterTitleBox >

                <Typography variant="h3" color={"#000"}>
                  {title}
                </Typography>
                {/* <ButtonGroup sx={{ flexDirection: "row", width: "100%", }}> */}
                {/* </ButtonGroup> */}

                {USER_ROLES.LENSKART_STORE === user.data.role && <Box p={2}>
                  <Button
                    onClick={onCreateBtnClick}
                    sx={{
                      width: "100%",
                      height: "6vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",

                    }}
                    variant="contained"
                  >
                    <AddIcon /> &nbsp;
                    <Typography variant="h4" sx={{ display: "flex" }}>
                      Add Customer
                    </Typography>
                  </Button>
                </Box>}
              </FilterTitleBox>


              {countLoading ? (
                <Box
                  mt={4}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    height: "15vh",
                  }}
                >
                  {[0, 1, 2, 3].map((row) => (
                    <Skeleton
                      key={row}
                      variant="rectangular"
                      width={"24%"}
                      height={120}
                      sx={{
                        borderRadius: "8px",
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      }}
                    />
                  ))}
                </Box>
              ) : (<Box mt={4} gap={4} sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: "row", height: "15vh", width: "100%" }}>
                {count?.map((item) => (
                  <ButtonComponentsUi count={item?.count ?? 0} onSubmit={() => setButtonStatus(item?.customer_type)} loading={loading} colorType={buttonStatus === item?.customer_type} Title={toTitleCase(item?.label)} />
                ))
                }
              </Box>)}



              <FilterTitleBox >

                <PaddingBoxInDesktop
                  sx={{
                    display: "flex",
                    width: "30vw",
                    flexDirection: "flex-start",
                  }}
                >
                  <TimeRangeSelector
                    onChange={(val) => setFilters({ ...filters, ...val })}
                  />
                </PaddingBoxInDesktop>

                <PaddingBoxInDesktop
                  // mb={4}
                  p={2}
                  sx={{ display: "flex", width: "20vw" }}
                >
                  <AsyncSearchBar
                    fullWidth
                    title="Search Name | Email"
                    size="small"
                    placeholder={"Search Name | Email"}
                    defaultValue={filters.search}
                    onChange={(changedVal) => {
                      setFilters({
                        ...filters, pageNo: 1,
                        pageSize: 10, search: changedVal
                      });
                    }}
                  />
                </PaddingBoxInDesktop>
              </FilterTitleBox>
            </Box>

            <Box p={2} sx={{ minHeight: "40vh" }}>
              <DataTable
                columns={columns}
                rows={list?.result ? list.result : []}
                count={list.total ?? 0}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
              />
            </Box>
          </Paper ></>
      </Box >
    </>
  );
};
export default CustomerListUi;