import React, { useEffect, useMemo, useState } from "react";
import SalesListUi from "./SalesListUi";
import { useDispatch, useSelector } from "react-redux";
import { fetchSalesCountsApi } from "../../apis/sales.api";
import moment from "moment";
import { callApiAction } from "../../store/actions/commonAction";
import { FetchCustomerOrSalesApi } from "../../apis/customer.api";
import { findObjectKeyByValue } from "../../utils/main";
import { GENDER, SALEABLE_TYPE } from "../../utils/constants";
import { fetchProductAction } from "../../store/actions/setting.Action";
import { Typography } from "@mui/material";
import { openModal } from "../../store/actions/modalAction";
import CustomerInformationController from "../customer/CustomerInformationController";

const SalesListController = () => {
  const [buttonStatus, setButtonStatus] = useState(SALEABLE_TYPE.ALL);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [count, setCount] = useState([]);
  const fetchcountApi = fetchSalesCountsApi;
  const fetchApi = FetchCustomerOrSalesApi;
  const { settings } = useSelector((state) => state);
  const [selectedButtonId, setSelectedButtonId] = useState(null);

  const [list, setList] = useState({});
  const [countLoading, setCountLoading] = useState(false);

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    sort: "",
    sortDirection: -1,
  });

  const onVeiw = (id) => {
    dispatch(
      openModal(
        <CustomerInformationController id={id} />,
        "xl",
        false,
        "CustomerInformation"
      )
    );
  };

  const columns = useMemo(() => {
    return [
      {
        id: 1,
        minWidth: "50px",
        fieldName: "product_name",
        label: "Product Name",
        align: "left",
        sort: true,
        renderValue: (params) => params?.sold_details?.product?.product_name,
      },
      {
        id: 2,
        minWidth: "50px",
        fieldName: "box_id",
        label: "Box Id",
        align: "left",
        sort: true,
        renderValue: (params) => params.sold_details.box_id,
      },
      {
        id: 3,
        fieldName: "name",
        label: "Customer Name",
        align: "left",
        sort: true,
        renderValue: (params) => (
          <Typography
            onClick={() => {
              onVeiw(params._id);
            }}
            sx={{ cursor: "pointer" }}
          >
            {params?.first_name + " " + params?.last_name}
          </Typography>
        ),
      },
      {
        id: 4,
        fieldName: "mrp",
        label: "Amount",
        align: "left",
        sort: true,
        renderValue: (params) => params?.sold_details?.amount,
      },
    ];
  }, [dispatch]);

  const fetchCountList = () => {
    setCountLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await fetchcountApi({ sales_type: buttonStatus, ...filters }),
        (response) => {
          setCount(response);
          setCountLoading(false);
        },
        (err) => {
          setCountLoading(false);
        }
      )
    );
  };
  console.log("object count", count);

  useEffect(() => {
    fetchCountList();
  }, [filters, buttonStatus]);

  useEffect(() => {
    if (count.length > 0 && !selectedButtonId) {
      setSelectedButtonId(count[0]._id);
    }
  }, [count]);

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await fetchApi({
            ...filters,
            sales_type: buttonStatus,
            product: selectedButtonId,
          }),
        (response) => {
          setList(response);
          console.log("res..........", response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters, buttonStatus, selectedButtonId]);

  return (
    <SalesListUi
      setButtonStatus={setButtonStatus}
      buttonStatus={buttonStatus}
      loading={loading}
      filters={filters}
      setFilters={setFilters}
      count={count}
      setList={setList}
      list={list}
      columns={columns}
      setSelectedButtonId={setSelectedButtonId}
      selectedButtonId={selectedButtonId}
      countLoading={countLoading}
    />
  );
};

export default SalesListController;
