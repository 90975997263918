import React from "react";
import { useSelector } from "react-redux";
import CustomerListController from "./CustomerListController";
import CustomerAdminController from "./CustomerAdminController";
import { USER_ROLES } from "../../utils/constants";
import { Box } from "@mui/material";

const Customer = () => {
  const { user } = useSelector((state) => state);
  return (
    <Box overflow={"auto"} sx={{ scrollbarWidth: "none" }}>
      {(user.data.role == USER_ROLES.LENSKART_ADMIN || user.data.role == USER_ROLES.WEHEAR_ADMIN || user.data.role == USER_ROLES.WEHEAR_MANAGER)  && (
        <CustomerAdminController />
      )}
      {(user.data.role == USER_ROLES.LENSKART_STORE || user.data.role == USER_ROLES.AUDIOLOGIST || user.data.role == USER_ROLES.SALES_PERSON) && (
        <CustomerListController/>
      )}
    </Box>
  );
};

export default Customer;
