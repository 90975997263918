import { actions } from "../../utils/constants"

export const addInventoryListAction = (data) => {
    return { type: actions.SET_INVENTORY_LIST, data }
}

export const updateInventoryListAction = (box_id, field, value) => ({
    type: actions.UPDATE_INVENTORY_LIST,
    boxId: box_id, // Ensure this matches the reducer key
    field,
    value,
});

