import * as React from 'react';

import { styled } from '@mui/material/styles';
import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

const SubmitButtonStyled = styled(Button)(({ theme, normal }) => ({

    width: "100%",
    height:"5vh",
    fontSize:"18px",
}));

const SubmitButton = ({ title, loading, ...props }) => {
    return <SubmitButtonStyled disabled={loading} disableElevation variant='contained' {...props}>
        {props.icon}
        {!loading && title}

        {loading && <CircularProgress size={25} color={props.variant == "outlined" ? "primary" : "light"} ml={4} />}

    </SubmitButtonStyled>
}

export default SubmitButton

