import {
  Button,
  ButtonGroup,
  Grid2,
  InputAdornment,
  Paper,
  Skeleton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Box, useMediaQuery } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import SearchIcon from "../../assets/images/searchIcon.svg";
import loadingImg from "../../assets/images/loading.gif";
import CustomerListController from "./CustomerListController";
import { getUserApi } from "../../apis/user.api";
import { callApiAction } from "../../store/actions/commonAction";
import CustomizedAccordions from "../../components/AccordionComponent";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  // flexWrap: "wrap",
  flexDirection: "row",
  alignItems: "center",
  padding: 4,
  marginTop: "10px", // [theme.breakpoints.down("md")]: {
  //   flexDirection: "row",
  // },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const StoreDetails = ({ list }) => {
  console.log("this is riya", list);
  return (
    <Box>
      {list?.map((item) => (
        <Box mb={2}>
          <CustomizedAccordions isTable={true} item={item} />
        </Box>
      ))}
    </Box>
  );
};

const StoreWiseCustomerData = () => {
  const fetchApi = getUserApi;
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    searchable: ["name", "address"],
    search: "",
    role: "",
    sort: "",
    sortDirection: -1,
    deleted: null,
  });
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters, inOut: true, isStoreData: true }),
        (response) => {
          setList(response.result);
          console.log("this is list", response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);
  return (
    <>
      <Box
        p={3}
        sx={{ marginTop: "20px", backgroundColor: "white", minHeight: "55vh" }}
      >
        <Box>
          <TextField
            id="outlined-start-adornment"
            fullWidth
            onChange={(e) => {
              setFilters({ ...filters, search: e.target.value });
            }}
            placeholder="Search Area/store"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "40vh",
            }}
          >
            <img src={loadingImg} alt="Loading.." />
          </Box>
        ) : list.length > 0 ? (
          <Box sx={{ marginTop: "20px" }}>
            <StoreDetails list={list} />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "40vh",
            }}
          >
            <Typography sx={{ fontWeight: "700" }}>No Store Found.</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

const CustomerAdminUi = ({
  title,
  filters,
  setFilters,
  list,
  roles,
  loading,
  onCreateBtnClick,
  columns,
  count,
  setButtonStatus,
  buttonStatus,
}) => {
  const [step, setStep] = useState(0);
  return (
    <>
      {/* <Box mb={3}>
          <Paper elevation={2} sx={{ width: "100%", padding: 4 }}>
            <Box mt={3} >
  
  
              <FilterTitleBox >
  
                <Typography variant="h3" color={"#000"}>
                  {title}
                </Typography>
  
                {USER_ROLES.LENSKART_STORE === user.data.role && <Box p={2}>
                  <Button
                    onClick={onCreateBtnClick}
                    sx={{
                      width: "100%",
                      height: "6vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
  
                    }}
                    variant="contained"
                  >
                    <AddIcon /> &nbsp;
                    <Typography variant="h4" sx={{ display: "flex" }}>
                      Add Customer
                    </Typography>
                  </Button>
                </Box>}
              </FilterTitleBox>
  
              <Grid2 p={2} size={count?.length / 3} justifyContent={"space-between"}>
                <Box mt={4} gap={4} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", flexDirection: "row", height: "15vh", width: "100%" }}>
                  {count?.slice(0, count.length - 1)?.map((item) => (
                    <ButtonComponentsUi count={item?.count ?? 0} onSubmit={() => setButtonStatus(item?.customer_type)} colorType={buttonStatus === item?.customer_type} Title={toTitleCase(item?.label)} />
                  ))
                  }
                </Box>
              </Grid2>
  
  
  
              <FiltersBox mt={3}>
                <PaddingBoxInDesktop
                  // mb={4}
                  p={2}
                  sx={{ display: "flex", width: "20vw" }}
                >
                  <AsyncSearchBar
                    fullWidth
                    title="Search Name | Email"
                    size="small"
                    placeholder={"Search Name | Email"}
                    defaultValue={filters.search}
                    onChange={(changedVal) => {
                      setFilters({
                        ...filters, pageNo: 1,
                        pageSize: 10, search: changedVal
                      });
                    }}
                  />
                </PaddingBoxInDesktop>
              </FiltersBox>
            </Box>
  
            <Box p={2} sx={{ minHeight: "40vh" }}>
              <DataTable
                columns={columns}
                rows={list ? list : []}
                count={list.length ?? 0}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
              />
            </Box>
          </Paper >
        </Box > */}
      <Box mb={2}>
        <ButtonGroup
          sx={{ color: "#101536" }}
          fullWidth
          size="large"
          aria-label="Large button group"
        >
          <Button
            sx={step == 1 && { backgroundColor: "white" }}
            variant={step == 0 ? "contained" : "outlined"}
            onClick={() => setStep(0)}
            fullWidth
          >
            ALL
          </Button>
          <Button
            sx={step == 0 && { backgroundColor: "white" }}
            variant={step == 1 ? "contained" : "outlined"}
            onClick={() => setStep(1)}
            fullWidth
          >
            Store wise
          </Button>
        </ButtonGroup>
      </Box>
      {step == 0 && <CustomerListController />}
      {step == 1 && <StoreWiseCustomerData />}
    </>
  );
};
export default CustomerAdminUi;
