import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import cardBackground from "../../../assets/images/kem_cho.svg";
import StoreController from "./StoreController";
import WareHouseController from "./WareHouseController";
import { useSelector } from "react-redux";
import { USER_ROLES } from "../../../utils/constants";

const StockUi = ({ data }) => {
  const [step, setStep] = useState(0);
  const { user } = useSelector(state => state)

  return (
    <Box>
      {user.data.role == USER_ROLES.WEHEAR_ADMIN && <Box p={2} m={1} mb={2} sx={{ backgroundColor: "#020043", borderRadius: "5px" }}> <Typography textAlign={"center"} variant="h3" sx={{ fontWeight: "700", color: "white" }}>Lenskart Inventory</Typography></Box>}
      <Box
        sx={{
          width: "100%",
          padding: "10px 20px 10px 20px",
          borderRadius: "8px",
          border: "1px #E4E4E4 solid",
          backgroundImage: `url(${cardBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* Store's Stock */}
        <Box
          sx={
            step == 0
              ? {
                width: "33%",
                textAlign: "center",
                cursor: "pointer",
                border: "1px solid  #28B867 ",
                borderLeft: "5px solid  #28B867 ",
                borderRadius: "8px",
                backgroundColor: "#EAFAF1",
                padding: 1,
              }
              : {
                width: "33%",
                textAlign: "center",
                cursor: "pointer",
                padding: 1,
              }
          }
          onClick={() => {
            setStep(0);
          }}
        >
          <Typography
            gutterBottom
            sx={{
              color: "#020043",
              fontSize: "20px",
              lineHeight: "24px",
              fontWeight: "500",
            }}
          >
            All Store Stock
          </Typography>
          <Typography
            sx={{
              color: "#020043",
              fontSize: "40px",
              lineHeight: "48px",
              fontWeight: "700",
            }}
            gutterBottom
          >
            {data?.storeData?.length > 0 ? data?.storeData[0]?.count : 0}
          </Typography>
        </Box>

        {/* Warehouse's Stock */}
        <Box
          sx={
            step == 1
              ? {
                width: "33%",
                textAlign: "center",
                cursor: "pointer",
                border: "1px solid  #28B867 ",
                borderLeft: "5px solid  #28B867 ",
                borderRadius: "8px",
                backgroundColor: "#EAFAF1",
                padding: 1,
              }
              : {
                width: "33%",
                textAlign: "center",
                cursor: "pointer",
                padding: 1,
              }
          }
          onClick={() => {
            setStep(1);
          }}
        >
          <Typography
            gutterBottom
            sx={{
              color: "#020043",
              fontSize: "20px",
              lineHeight: "24px",
              fontWeight: "500",
            }}
          >
            Warehouse Stock
          </Typography>
          <Typography
            sx={{
              color: "#020043",
              fontSize: "40px",
              lineHeight: "48px",
              fontWeight: "700",
            }}
            gutterBottom
          >
            {data?.wareHouse?.length > 0 ? data?.wareHouse[0]?.count : 0}
          </Typography>
        </Box>

        {/* Vertical Divider and Total Stock */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginRight: "250px",
          }}
        >
          <Box
            sx={{
              width: "1px",
              height: "70px",
              backgroundColor: "green",
              marginRight: "15px",
            }}
          />
          {/* Total Stock */}
          <Box sx={{ textAlign: "center" }}>
            <Typography
              gutterBottom
              sx={{
                color: "#020043",
                fontSize: "20px",
                lineHeight: "24px",
                fontWeight: "500",
              }}
            >
              Total Stock
            </Typography>
            <Typography
              sx={{
                color: "#020043",
                fontSize: "40px",
                lineHeight: "48px",
                fontWeight: "700",
              }}
              gutterBottom
            >
              {Number(
                data?.wareHouse?.length > 0 ? data?.wareHouse[0]?.count : 0
              ) +
                Number(
                  data?.storeData?.length > 0 ? data?.storeData[0]?.count : 0
                )}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Button
          sx={{
            width: "49%",
            height: 70,
            borderRadius: "8px",
            fontWeight: "700",
            color: step == 0 ? "white" : "#28B867",
            border: step == 0 ? "1px solid #E4E4E4" : "1px solid #28B867",
            backgroundColor: step == 0 ? "#020043" : "white",
            border: "2px solid #28B867",
          }}
          onClick={() => {
            setStep(0);
          }}
          variant="contained"
        >
          STORES
        </Button>
        <Button
          sx={{
            width: "49%",
            height: 70,
            borderRadius: "8px",
            fontWeight: "700",
            color: step == 1 ? "white" : "#28B867",
            border: step == 1 ? "1px solid #E4E4E4" : "1px solid #28B867",
            backgroundColor: step == 1 ? "#020043" : "white",
          }}
          onClick={() => {
            setStep(1);
          }}
          variant="outlined"
        >
          {" "}
          WAREHOUSE
        </Button>
      </Box> */}
      {step == 0 && (
        <Box sx={{ height: "100%" }}>
          {" "}
          <StoreController />
        </Box>
      )}
      {step == 1 && (
        <Box sx={{ height: "100%" }}>
          {" "}
          <WareHouseController title={"Warehouse Stock"} />
        </Box>
      )}
    </Box>
  );
};

export default StockUi;
