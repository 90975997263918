import React, { useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary, {
  accordionSummaryClasses,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIos";
import lkglass from "../assets/images/lkglass.svg";
import { Box, Card, CardContent, Divider, Grid2, Grid22 } from "@mui/material";
import { ArrowDownward, KeyboardArrowDown } from "@mui/icons-material";
import ButtonComponentsUi from "./button/ButtonComponentsUi";
import DashboardProductCounts from "../pages/dashboard/DashboardProductCounts";
import ClickButton from "./button/ClickButton";
import { callApiAction } from "../store/actions/commonAction";
import { fetchStockByIdApi, fetchStockByStoreIdApi } from "../apis/stock.api";
import { useDispatch } from "react-redux";
import loadingImg from "../assets/images/loading.gif";
import { getCustomerApi } from "../apis/customer.api";
import { CUSTOMER_TYPE, GENDER } from "../utils/constants";
import DataTable from "./tables/DataTable";
import { findObjectKeyByValue } from "../utils/helper";
import CustomerListController from "../pages/customer/CustomerListController";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "1px solid #CECCFF",
  borderRadius: "5px",
  padding: "2px",
  marginBottom: theme.spacing(1),
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <KeyboardArrowDown sx={{ fontSize: "40px", color: "#020043" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  padding: theme.spacing(0),
  flexDirection: "row",
  alignItems: "center",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(2),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  justifyContent: "space-evenly",
  backgroundColor: "white",
  borderTop: "1px solid #CECCFF ",
}));

const InfoCard = ({ title, total, trialCount, sellCount }) => {
  return (
    <Card
      sx={{
        width: 280,
        borderRadius: "8px",
        backgroundColor: "#ECFDF5", // Slightly lighter green
        border: "1px solid #28B867", // Softer border color
      }}
    >
      <Box mt={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // Center the title
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            color="text.primary"
            sx={{ fontWeight: "bold", fontSize: "20px" }}
          >
            {title}
          </Typography>
        </Box>

        <Box
          p={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#FFFFFF",
            borderRadius: "4px",
            // padding: "4px 16px 4px 16px",
            border: "1px solid #D5F6E3",
            marginTop: 2, // Add spacing between the title and details box
          }}
        >
          <Box sx={{ textAlign: "center", flex: 1 }}>
            <Typography
              variant="body2" // Slightly smaller text
              color="#28B867"
            >
              Saleable
            </Typography>
            <Typography variant="h2" sx={{ fontWeight: "bold" }}>
              {sellCount}
            </Typography>
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#D1E7DD", marginX: 1 }} // Subtle divider
          />
          <Box sx={{ textAlign: "center", flex: 1 }}>
            <Typography variant="body2" color="#28B867">
              Trial
            </Typography>
            <Typography variant="h2" sx={{ fontWeight: "bold" }}>
              {trialCount}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

const BoxContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap", // Allow wrapping to the next row
  gap: "20px",
  justifyContent: "space-between", // Space between items
  marginTop: theme.spacing(2),
}));

const ReusableAccordion = ({
  id,
  title,
  address,
  children,
  expanded,
  onChange,
  data,
  loading,
  filters,
  setFilters,
  isTable,
}) => {
  const dispatch = useDispatch();
  const columns = useMemo(() => {
    return [
      {
        id: 1,
        minWidth: "50px",
        fieldName: "first_name",
        label: "Full Name",
        align: "left",
        sort: true,
        renderValue: (params) => params.first_name + " " + params.last_name,
      },
      {
        id: 2,
        fieldName: "email",
        label: "Email",
        align: "left",
        sort: true,
      },

      {
        id: 3,
        fieldName: "phone",
        label: "Phone",
        align: "left",
        sort: true,
      },
      {
        id: 8,
        minWidth: "50px",
        fieldName: "gender",
        label: "Gender",
        align: "left",
        renderValue: (params) =>
          findObjectKeyByValue(parseInt(params.gender), GENDER),
      },
      {
        id: 5,
        fieldName: "customer_type",
        label: "Type",
        align: "left",
        renderValue: (params) =>
          findObjectKeyByValue(parseInt(params.customer_type), CUSTOMER_TYPE),
      },
    ];
  }, [dispatch]);
  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary>
        <Box>
          <Typography
            variant="h5"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <img src={lkglass} />
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "gray", marginLeft: "auto" }}
          >
            {address}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {!loading ? (
          <>
            {!isTable ? (
              <BoxContainer>
                {data?.map((temp) => (
                  <InfoCard
                    title={temp?.product_name}
                    trialCount={temp.inventory_type1_count ?? 0}
                    sellCount={temp.inventory_type2_count ?? 0}
                  />
                ))}
              </BoxContainer>
            ) : (
              // <DataTable
              //   columns={columns}
              //   rows={data ? data : []}
              //   count={data?.length ?? 0}
              //   filters={filters}
              //   setFilters={setFilters}
              //   loading={loading}
              // />
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CustomerListController isAccordian={true} id={id} />
              </Box>
            )}
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "120px",
            }}
          >
            <img width={30} src={loadingImg} alt="loading.." />
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default function CustomizedAccordions({ item, isTable }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const api = fetchStockByStoreIdApi;

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    searchable: ["name", "email"],
    search: "",
    role: "",
    sort: "",
    sortDirection: -1,
    deleted: null,
  });

  const fetchList = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await api({ id }),
        (response) => {
          setData(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
    if (isExpanded) {
      fetchList(panel);
    }
  };

  return (
    <div>
      <ReusableAccordion
        key={item?._id}
        isTable={isTable}
        title={item?.name}
        address={item?.address}
        expanded={expanded === item?._id}
        onChange={handleChange(item?._id)}
        id={item?._id}
        data={data}
        loading={loading}
        filters={filters}
        setFilters={setFilters}
      />
    </div>
  );
}