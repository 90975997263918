import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Typography, ListItem, CircularProgress } from "@mui/material";
import moment from "moment";
import { Add } from "@mui/icons-material";

import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { StyledSearchBar } from "../../components/inputs/SearchBar";
import CustomInput from "../../components/inputs/CustomInputs";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { fetchProductApi } from "../../apis/product.api";
import { callApiAction } from "../../store/actions/commonAction";
import { getCustomerByIdApi } from "../../apis/customer.api";
import { CenteredBox } from "../../components/layouts/OneViewBox";

const TrialsUi = ({ setLoading, loading, id, onSubmit, setFields, fields }) => {
  const dispatch = useDispatch();
  const [isDataFetched, setIsDataFetched] = useState(false); // Track if data is fetched
  const [loadingProducts, setLoadingProducts] = useState(false); // Track product API loading

  const fetchById = (id) => {
    setLoading(true); // Start loading before the API call
    dispatch(
      callApiAction(
        async () => await getCustomerByIdApi({ id }),
        async (response) => {
          // Ensure default values for the trials and set fetched data
          const updatedTrials = response.trial.map((trial) => ({
            ...trial,
            product: trial.product || "", // Ensure default empty values
            product_name: trial.product_name || "", // Ensure default empty values
          }));
          setFields((prevFields) => ({
            ...prevFields,
            trial: updatedTrials, // Update trials with fetched data
          }));
          setIsDataFetched(true); // Mark as data fetched
          setLoading(false); // Turn off the loading state after data is fetched
        },
        (err) => {
          setFields((prevFields) => ({ ...prevFields, err }));
          setLoading(false); // Turn off loading in case of error
          setIsDataFetched(true); // Mark as data fetched even in case of error
        }
      )
    );
  };

  useEffect(() => {
    if (id) {
      // Set default trials data if fields.trial is empty or undefined
      if (!fields.trial || fields.trial.length === 0) {
        setFields({
          ...fields,
          trial: [
            {
              product: "",
              product_name: "",
              box_id: "",
              date: moment(),
              feedback: "",
            },
          ],
        });
      }

       }
  }, [id, setFields, fields]);

  useEffect(()=>{
    const timeoutId = setTimeout(() => {
        fetchById(id); // Fetch customer data after delay
      }, 1000);

      return () => clearTimeout(timeoutId);

  },[id])

  const handleAddTrial = () => {
    setFields((prevData) => {
      const updatedTrial = [
        ...prevData.trial,
        { product: "", box_id: "", date: moment(), feedback: "" },
      ];
      return { ...prevData, trial: updatedTrial };
    });
  };

  const handleFieldChange = (index, field, value) => {
    const updatedTrial = [...fields.trial];
    if (field === "product") {
      updatedTrial[index] = {
        ...updatedTrial[index],
        product: value.product,
        product_name: value.product_name,
      };
    } else {
      updatedTrial[index] = { ...updatedTrial[index], [field]: value };
    }
    setFields({ ...fields, trial: updatedTrial });
  };

  return (
    <CustomDialog
      id="CustomerInformation"
      title="Update Customer"
      closeText="Close"
      confirmText="Update"
      onSubmit={onSubmit}
      loading={loading}
    >
      <Box mt={4} p={4} height="60vh" sx={{ overflowY: "auto" }}>
        {/* Show loading until data is fetched */}
        {isDataFetched ? (
          fields?.trial?.map((trial, index) => (
            <Box key={index} mt={4}>
              <Typography variant="h3">Trial Product - {index + 1}</Typography>
              <Box mt={2}>
                <AsyncDropDown
                  defaultVal={
                    trial.product && trial.product_name
                      ? { _id: trial.product, product_name: trial.product_name }
                      : null
                  }
                  lazyFun={async (params) => {
                    setLoadingProducts(true); // Start loading products
                    const response = await fetchProductApi({
                      ...params,
                      allStatus: true,
                    });
                    setLoadingProducts(false); // Stop loading products
                    return response; // Return the products
                  }}
                  OptionComponent={({ option, ...rest }) => (
                    <ListItem {...rest}>{option.product_name}</ListItem>
                  )}
                  onChange={(changedVal) =>
                    handleFieldChange(index, "product", {
                      product: changedVal?._id || "",
                      product_name: changedVal?.product_name || "",
                    })
                  }
                  titleKey="product_name"
                  valueKey="_id"
                  InputComponent={(params) => (
                    <StyledSearchBar
                      placeholder="Select Product*"
                      {...params}
                      margin="none"
                    />
                  )}
                  disabled={!fields.trial || fields.trial.length === 0}
                  loading={loadingProducts} // Show loading spinner while fetching products
                />
              </Box>
              <Box mt={2}>
                <CustomInput
                  value={trial.box_id || ""}
                  onChange={(e) =>
                    handleFieldChange(index, "box_id", e.target.value)
                  }
                  type="text"
                  label="Enter Box Id*"
                />
              </Box>
              <Box mt={2}>
                <CustomInput
                  value={trial.feedback || ""}
                  onChange={(e) =>
                    handleFieldChange(index, "feedback", e.target.value)
                  }
                  type="text"
                  label="Trial Feedback*"
                />
              </Box>
            </Box>
          ))
        ) : (
            <CenteredBox><CircularProgress /></CenteredBox> 
                //   <Typography variant="h5">Loading data, please wait...</Typography>
        )}
        {isDataFetched && <Box mt={2} display="flex" justifyContent="center">
          <Button variant="outlined" onClick={handleAddTrial}>
            <Typography variant="h5" color="primary">
              Add
            </Typography>
            <Add />
          </Button>
        </Box>}
      </Box>
    </CustomDialog>
  );
};

export default TrialsUi;