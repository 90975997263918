import { actions } from "../../utils/constants";
const removeDuplicates=(arr)=> {
    var unique = [];
    if (arr[0]) {
        unique.push(arr[0])
    }
    
    for (let i = 1; i < arr.length; i++) {
        let exist = false
        for (let j = 0; j < unique.length; j++) {
            if (unique[j].box_id == arr[i].box_id) {
                exist = true
                break
            }

        }
        if (!exist)
            unique.push(arr[i])
    }
    unique.sort()
    return unique.sort((a, b) => a - b);
}
const InventoryListReducer = (state = [], action) => {

    switch (action.type) {
        case actions.SET_INVENTORY_LIST: {

            const old = [...state]
            const newArr = old.concat(action.data)

            const finalArray = removeDuplicates([...newArr])



            return finalArray
        };

        case actions.UPDATE_INVENTORY_LIST: {
            const { boxId, field, value } = action;
        
            const updatedState = state.map((box) =>
                box.box_id === boxId
                    ? { ...box, [field]: value }
                    : box
            );
        
            console.log("Updated State:", updatedState);
            return updatedState;
        }
        
        
        

        default: return { ...state }
    }

}
export default InventoryListReducer